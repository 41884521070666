import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchData,
  removeMeasurementMediaFromOrderPage,
  removeOrderMedia,
  sendSMS,
  sendFeedback,
  updateMeasurementMediaCommentInOrder,
  updateOrderMediaCommentInOrder,
} from "../../actions/data.actions";
import "../../components/styles.css";
import dataService from "../../services/data.service";
import { getPayUrlByCity } from "../../utils";
import QRModal from "../common/QRModal";
import MediaDownloader from "../media/MediaDownloader";
import MediaGalery from "../media/MediaGalery";

class OrderItem extends Component {
  checkIsMediaProcessed = () => {
    for (var i = 0; i < this.props.item.order_media.length; i++) {
      if (!this.props.item.order_media[i].processed) {
        return false;
      }
    }

    return true;
  };
  async refreshWhileProccessing() {
    await new Promise((r) => setTimeout(r, 7000));
    this.props.getData(this.props.item.id);
  }

  componentDidMount() {
    if (!this.checkIsMediaProcessed()) this.refreshWhileProccessing();
  }

  componentDidUpdate() {
    if (!this.checkIsMediaProcessed()) this.refreshWhileProccessing();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <div
        className="card mt-4 p-0 shadow-sm"
        style={{ border: "solid 2px gray" }}
      >
        <div className="row mt-0 text-start text-lg-center">
          <div className="col-12">
            <div className="card d-none d-lg-block border-0">
              <div className="card-header text-center order_header">
                <div className="row">
                  <div className="col-5">
                    <div className="row">
                      <div className="col-2"> Дата </div>
                      <div className="col-3"> ФИО </div>
                      <div className="col-2"> № Заказа </div>
                      <div className="col-3"> Адрес </div>
                      <div className="col-2"> Город </div>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="row">
                      <div className="col-4"> Фото c замера</div>
                      <div className="col-4"> Видео с замера</div>
                      <div className="col-2"> Паспорт монтажа </div>
                      <div className="col-2"> Паспорт заказа </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card border-0">
              <div className="col-12 card-body order_body">
                <div className="row">
                  <div className="col-12 d-block col-lg-5">
                    <div className="row">
                      <div className="col-12 col-lg-2 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            Дата:
                          </div>
                          <div className="col-8 col-lg-12">
                            {this.props.item.date}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-3 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            ФИО:
                          </div>
                          <div className="col-8 col-lg-12">
                            {this.props.item.appointed_installers.map(
                              (empl, idx) => (
                                <p key={idx}>{empl.name}</p>
                              )
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-2 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            № заказа:
                          </div>
                          <div className="col-8 col-lg-12">
                            {this.props.item.order_id}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-3 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            Адрес:
                          </div>
                          <div className="col-8 col-lg-12">
                            {this.props.item.address}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-2 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            Город:
                          </div>
                          <div className="col-8 col-lg-12">
                            {this.props.item.city}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-7">
                    <div className="row">
                      <div className="col-12 col-lg-4 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            Фото с замера:
                          </div>
                          <div className="col-8 col-lg-12">
                            {this.props.item.measurement ? (
                              <MediaGalery
                                displayedPreviewCount={2}
                                mimeType="image"
                                parentRowId={this.props.item.id}
                                removeFunc={this.props.removeMeasurementMedia}
                                media={
                                  this.props.item.measurement.measurement_media
                                }
                                isShowRemoveButton={false}
                                updateCommentFunc={
                                  this.props.updateMeasurementMediaComment
                                }
                                isCommentEditable={false}
                              />
                            ) : (
                              "Замер не был назначен"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-4 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            Видео с замера:
                          </div>
                          <div className="col-8 col-lg-12">
                            {this.props.item.measurement ? (
                              <MediaGalery
                                displayedPreviewCount={2}
                                mimeType="video"
                                parentRowId={this.props.item.id}
                                removeFunc={this.props.removeMeasurementMedia}
                                media={
                                  this.props.item.measurement.measurement_media
                                }
                                isShowRemoveButton={false}
                                updateCommentFunc={
                                  this.props.updateMeasurementMediaComment
                                }
                                isCommentEditable={false}
                              />
                            ) : (
                              "Замер не был назначен"
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-2 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            Паспорт монтажа:
                          </div>
                          <div className="col-8 col-lg-12">
                            <a
                              href={this.props.item.install_passport_file}
                              download
                              target="_blank"
                              rel="noreferrer"
                            >
                              <button className="pdf_button ms-3"></button>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-2 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            Паспорт заказа:
                          </div>
                          <div className="col-8 col-lg-12">
                            <a
                              href={this.props.item.order_passport_file}
                              download
                              target="_blank"
                              rel="noreferrer"
                            >
                              <button className="pdf_button ms-3"></button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card  mt-2 d-none d-lg-block border-0">
              <div className="card-header text-center order_header">
                <div className="row">
                  <div className="col-5">
                    <div className="row">
                      <div className="col-3"> Остаток по заказу </div>
                      <div className="col-3"> QR оплаты </div>
                      <div className="col-3"> Отправить ссылку на оплату </div>
                      <div className="col-3"> Отправить отзыв </div>
                    </div>
                  </div>

                  <div className="col-7">
                    <div className="row">
                      <div className="col-4"> Фото с монтажа</div>
                      <div className="col-4"> Видео с монтажа</div>
                      <div className="col-4"> Загрузить </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card border-0">
              <div className="col-12 card-body order_body">
                <div className="row">
                  <div className="col-12  d-block col-lg-5">
                    <div className="row">
                      <div className="col-12 col-lg-3 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            Остаток по заказу:
                          </div>
                          <div className="col-8 col-lg-12">
                            <div className="order_arrears">
                              {this.props.item.balance}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-3 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            QR оплаты:
                          </div>
                          <div className="col-8 col-lg-12">
                            <QRModal
                              value={getPayUrlByCity(this.props.item.city)}
                              styles="btn btn-success small_text order_buttons"
                              title=" QR для оплаты"
                              buttonText="QR оплаты"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-3 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            Отправить ссылку на оплату:
                          </div>
                          <div className="col-8 col-lg-12">
                            {this.props.item.sms_is_sended ? (
                              <button className="btn btn-info small_text order_buttons">
                                SMS отправлено
                              </button>
                            ) : (
                              <button
                                className="btn btn-success small_text order_buttons"
                                onClick={() => {
                                  this.props.sendSMS(this.props.item.id);
                                }}
                              >
                                Отправить
                              </button>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-3 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            Отправить отзыв:
                          </div>
                          <div className="col-8 col-lg-12">
                            {this.props.item.feedback_is_sended ? (
                              <button className="btn btn-info small_text order_buttons">
                                Отзыв отправлен
                              </button>
                            ) : (
                              <button
                                className="btn btn-success small_text order_buttons"
                                onClick={() => {
                                  this.props.sendFeedback(this.props.item.id);
                                }}
                              >
                                Отправить
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-7">
                    <div className="row">
                      <div className="col-12 col-lg-4 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            Фото с монтажа:
                          </div>
                          <div className="col-8 col-lg-12">
                            <MediaGalery
                              displayedPreviewCount={2}
                              mimeType="image"
                              parentRowId={this.props.item.id}
                              removeFunc={this.props.removeOrderMedia}
                              media={this.props.item.order_media}
                              isShowRemoveButton={true}
                              updateCommentFunc={
                                this.props.updateOrderMediaComment
                              }
                              isCommentEditable={true}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-4 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-4 small_text fw-bold">
                            Видео с монтажа:
                          </div>
                          <div className="col-8 col-lg-12">
                            <MediaGalery
                              displayedPreviewCount={2}
                              mimeType="video"
                              parentRowId={this.props.item.id}
                              removeFunc={this.props.removeOrderMedia}
                              media={this.props.item.order_media}
                              isShowRemoveButton={true}
                              updateCommentFunc={
                                this.props.updateOrderMediaComment
                              }
                              isCommentEditable={true}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-lg-4 d-block d-lg-inline mt-3 mt-lg-0">
                        <div className="row">
                          <div className="d-lg-none col-5 small_text fw-bold">
                            Загрузить:
                          </div>
                          <div className="col-12 col-lg-12 text-center">
                            <MediaDownloader
                              indexInStateTree={this.props.idx}
                              payload={{
                                orderId: parseInt(this.props.item.id),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    item: state.data.results[ownProps.idx],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getData: (id) => {
      dispatch(fetchData(dataService.getOrder, id, ownProps.idx));
    },

    sendSMS: (id) => {
      dispatch(sendSMS(id, ownProps.idx));
    },

    sendFeedback: (id) => {
      dispatch(sendFeedback(id, ownProps.idx));
    },

    removeOrderMedia: (mediaId, orderId, indexOfMediaInStateTree) => {
      dispatch(
        removeOrderMedia(
          mediaId,
          orderId,
          ownProps.idx,
          indexOfMediaInStateTree
        )
      );
    },

    removeMeasurementMedia: (
      mediaId,
      measurementId,
      indexOfMediaInStateTree
    ) => {
      dispatch(
        removeMeasurementMediaFromOrderPage(
          mediaId,
          measurementId,
          ownProps.idx,
          indexOfMediaInStateTree
        )
      );
    },

    updateOrderMediaComment: (mediaId, orderId, data) => {
      dispatch(updateOrderMediaCommentInOrder(mediaId, orderId, data));
    },

    updateMeasurementMediaComment: (mediaId, orderId, data) => {
      dispatch(updateMeasurementMediaCommentInOrder(mediaId, orderId, data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
