import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import  Orders  from "./components/order/Orders";
import  Remains  from "./components/remains/Remains";
import  Gallery  from "./components/gallery/Gallery";
import Login from "./components/login/Login";
import AuthService from "./services/auth.service";
import LeftSide from "./components/common/LeftSide";
import Employees from "./components/employee/Employees";

import TopSide from "./components/common/TopSide";
import { Page404 } from "./config.consts";
import Measurements from "./components/measurement/Measurements";
import OrderGaleryPage from "./components/order/OrderGaleryPage";
import OrderSliderPage from "./components/order/OrderSliderPage";
import MeasurementGaleryPage from "./components/measurement/MeasurementGaleryPage";
import MeasurementSliderPage from "./components/measurement/MeasurementSliderPage";
import AdminSettingsPage from "./components/admin_settings/AdminSettingsPage";

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser()
    };
  }

  componentDidMount() {
    document.title = "Личный кабинет"
    const appRoot = document.getElementById("root")
    appRoot.setAttribute("notranslate", true)
    document.documentElement.lang = "ru"
    document.documentElement.translate = false;
  }

  getRouteSwitchForUserType(currentUser) {
    if (!currentUser)
      return (
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />

                <Route exact path="/order_gallery/:id" component={OrderGaleryPage} />
                <Route exact path="/order_slider/:id" component={OrderSliderPage} />
                <Route exact path="/measurement_gallery/:id" component={MeasurementGaleryPage} />
                <Route exact path="/measurement_slider/:id" component={MeasurementSliderPage} />


                <Route component={Page404} />
              </Switch>
              )


    if (currentUser.is_superuser) {
      return (
              <Switch>
                <Route exact path="/" component={Orders} />
                <Route exact path="/gallery" component={Gallery} />
                <Route exact path="/measurements" component={Measurements} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/employees" component={Employees} />
                <Route exact path="/order_gallery/:id" component={OrderGaleryPage} />
                <Route exact path="/order_slider/:id" component={OrderSliderPage} />
                <Route exact path="/measurement_gallery/:id" component={MeasurementGaleryPage} />
                <Route exact path="/measurement_slider/:id" component={MeasurementSliderPage} />
                <Route exact path="/settings" component={AdminSettingsPage} />
                <Route component={Page404} />
              </Switch>
            )
      }

      if (currentUser.type === "m") {
        return (
                <Switch>
                  <Route exact path="/" component={Measurements} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/gallery" component={Gallery} />
                  <Route exact path="/order_gallery/:id" component={OrderGaleryPage} />
                  <Route exact path="/order_slider/:id" component={OrderSliderPage} />
                  <Route exact path="/measurement_gallery/:id" component={MeasurementGaleryPage} />
                  <Route exact path="/measurement_slider/:id" component={MeasurementSliderPage} />
                  <Route component={Page404} />
                </Switch>
              )
        }


      if (currentUser.type === "i") {
        return (
                <Switch>
                  <Route exact path="/" component={Orders} />
                  <Route exact path="/gallery" component={Gallery} />
                  <Route exact path="/remains" component={Remains} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/order_gallery/:id" component={OrderGaleryPage} />
                  <Route exact path="/order_slider/:id" component={OrderSliderPage} />
                  <Route exact path="/measurement_gallery/:id" component={MeasurementGaleryPage} />
                  <Route exact path="/measurement_slider/:id" component={MeasurementSliderPage} />
                  <Route component={Page404} />
                </Switch>
              )
        }

  }

  render() {
    const {currentUser} = this.state;

    return (
      <div className="container-fluid">
    <TopSide user = {currentUser} />
    <div className="row  flex-nowrap">

         <LeftSide user = {currentUser} />
        <Router>

        {this.getRouteSwitchForUserType(currentUser)}

        </Router>
      </div></div>
    );
  }
}

export default App;
