import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../../services/auth.service";
import logo from "../../assets/logo.png";
import { eyeSlashFillIcon } from "../icons.const";
import { eyeFillIcon } from "../icons.const";


export default class PasswordInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible: false
    };

    this.onChangeVisible = this.onChangeVisible.bind(this);
  }


  onChangeVisible(e) {
    this.setState({
      is_visible: !this.state.is_visible,
    });
  }



  render() {
    return (
                    <div className = "row g-0" >
                      <div className = "col-1"></div>

                      <div className = "col-10">
                      <input
                        type = {this.state.is_visible ? "text" : "password"}
                        className="form-control form-control-md"
                        name="password"
                        value={this.props.value}
                        onChange={this.props.onChange}
                        validations={this.props.validations}
                        required = {true}
                      />
                      </div>
                      <div className = "col-1">
                      <div
          onClick={this.onChangeVisible}
          className="btn text-break ms-1 showpass-btn"
          type="submit"
        >
        {this.state.is_visible ? eyeFillIcon : eyeSlashFillIcon }
        </div>
                      </div>
                      
                      
                    </div>
    );
  }
}
