import {IS_LOADING, CHANGE_PAGE, ON_SEARCH_CHANGE} from "../actions/data.actions";
import {OPEN_DATE_SEARCH, ENABLE_DATE_SEARCH, CHANGE_CITY_FILTER, SET_FROM_DATE, SET_TO_DATE} from "../actions/data.actions";

import {CITY_FILTER_ALL} from "../config.consts";

const initial_state = {
  isLoading: false,
  activePageNum: 1,
  searchLine: "",
  isDateSearchOpen: false,
  isDateSearchEnabled: false,
  currentCityFilter: CITY_FILTER_ALL,
  fromDate: new Date(Date.now() - ( 3600 * 1000 * 24 * 7)),
  toDate: Date.now()
}

export function pageState(state = initial_state, action) {
  switch (action.type) {

    case IS_LOADING:
      return {...state,isLoading: action.isLoading};

    case CHANGE_PAGE:
      return {...state, activePageNum: action.activePageNum};

    case ON_SEARCH_CHANGE:
      return {...state, searchLine: action.searchLine};

    case OPEN_DATE_SEARCH:
      return {...state, isDateSearchOpen:action.isOpen}

    case ENABLE_DATE_SEARCH:
      return {...state, isDateSearchEnabled:action.isEnable}
    
    case CHANGE_CITY_FILTER:  //city filter
      return {...state, currentCityFilter:action.cityFilter}

    case SET_FROM_DATE:
      return {...state, fromDate: action.date}

    case SET_TO_DATE:
      return {...state, toDate: action.date}

    default:
      return state;
  }
} 
