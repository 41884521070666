import React, { Component } from "react";
import AuthService from "../../services/auth.service";
import DataService from "../../services/data.service";
import { LoadingContent } from "../common/LoadingContent";
import EmployeeLoginModal from "./EmployeeLoginModal";
import { Redirect } from "react-router-dom";

export default class Employees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      isLoaded: false,
      data: [],
      activePageNum: 1,
      modalEmployee: null,
      isOpen: false,
    };

    this.retrieveEmployees = this.retrieveEmployees.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onChangeSearchInput = this.onChangeSearchInput.bind(this);
    this.searchAll = this.searchAll.bind(this);

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  retrieveEmployees() {
    DataService.getEmployees(this.state)
      .then((response) => {
        this.setState({
          data: response.data,
          isLoaded: true,
        });
      })
      .catch((error) => {
        this.setState({
          isLoaded: true,
          error,
        });
      });
  }

  handleToUpdate = (newChildrenState) => {
    var data = this.state.data;
    var results = [...data.results];
    var item = results[newChildrenState.rowNum];
    var itemUser = { username: "" };

    itemUser.username = newChildrenState.username;
    item.user = itemUser;
    results[newChildrenState.rowNum] = item;
    data.results = results;

    this.setState({ ...data });
  };

  componentDidMount() {
    this.retrieveEmployees();
    var newData = { ...this.state.data };
    newData.results = [];
    this.setState({
      data: newData,
    });
  }

  onChangeSearchInput(e) {
    this.setState({
      searchInput: e.target.value,
    });
  }

  searchAll() {
    var newData = { ...this.state.data };
    newData.results = [];
    this.setState(
      {
        activePageNum: 1,
        data: newData,
      },
      () => this.retrieveEmployees()
    );
  }

  handleCloseModal() {
    this.setState({ isOpen: false });
  }

  handleOpenModal(row, num) {
    this.setState({
      isOpen: !this.state.isOpen,
      modalEmployee: row,
      rowNum: num,
    });
  }

  onPageChange(page) {
    if (page) {
      if (this.state.activePageNum !== page) {
        this.setState({ activePageNum: page }, () => this.retrieveEmployees());
      }
    }
  }

  render() {
    const { currentUser, data, isLoaded, error } = this.state;

    if (!currentUser) return <Redirect to="/login" />;
    if (!currentUser.is_superuser) return <Redirect to="/orders" />;
    if (!isLoaded) return <LoadingContent />;
    if (error) return <>{error} </>;

    return (
      <div className="col-12 col-lg-10 col-xl-11">
        <div className="row pt-2 page_structure ">
          <div className="col-10 col-lg-9 col-xl-11 ps-3 ps-lg-0">
            <input
              className="form-control search_input"
              onChange={this.onChangeSearchInput}
              placeholder="Поиск"
              aria-label="Search"
            />
          </div>
          <div className="col-2 col-lg-3 col-xl-1 pb-2 ">
            <button
              style={{ background: "lightgray", border: "solid 1px gray" }}
              onClick={this.searchAll}
              className="btn text-break"
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </button>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">ИД</th>
                <th scope="col">ФИО</th>
                <th scope="col">Город</th>
                <th scope="col">Должность</th>
                <th scope="col">Учетная запись</th>
              </tr>
            </thead>
            <tbody>
              {data.results.map((val, idx) => (
                <tr
                  key={
                    this.state.data.page_count * this.state.data.current + idx
                  }
                >
                  <th scope="row">
                    {this.state.data.page_size * (this.state.data.current - 1) +
                      idx +
                      1}
                  </th>
                  <td>{val.id}</td>
                  <td>{val.name}</td>
                  <td>{val.city}</td>
                  <td>{val.type === "m" ? "Замерщик" : "Монтажник"}</td>
                  <td>
                    <button
                      onClick={() => this.handleOpenModal(val, idx)}
                      className={
                        `btn btn-sm  ` +
                        (val.user ? `btn-outline-primary` : `btn-outline-dark`)
                      }
                    >
                      {val.user ? val.user.username : "Отсутствует"}
                    </button>
                  </td>

                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <nav className="mt-2 " aria-label="...">
          <ul className="pagination flex-wrap">
            <li
              className={
                `mt-1 page-item ` +
                (this.state.data.current === 1 && "disabled")
              }
            >
              <button
                className="page-link"
                onClick={() => this.onPageChange(1)}
                aria-disabled="true"
              >
                &laquo;
              </button>
            </li>

            {Array(data.page_count)
              .fill()
              .map((_, i) =>
                data.current === i + 1 ? (
                  <li key={i + 1} className="mt-1 page-item active">
                    <button className="page-link">
                      {i + 1}
                    </button>
                  </li>
                ) : (
                  <li key={i + 1} className="mt-1 page-item">
                    <button
                      className="page-link"
                      onClick={() => this.onPageChange(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                )
              )}

            <li
              className={
                `mt-1 page-item ` +
                (this.state.data.page_count === this.state.data.current &&
                  "disabled")
              }
            >
              <button
                className="page-link"
                onClick={() => this.onPageChange(this.state.data.page_count)}
              >
                &raquo;
              </button>
            </li>
          </ul>
        </nav>

        <EmployeeLoginModal
          rowNum={this.state.rowNum}
          handleToUpdate={this.handleToUpdate}
          isOpen={this.state.isOpen}
          employee={this.state.modalEmployee}
          contentLabel="Minimal Modal Example"
        />
      </div>
    );
  }
}
