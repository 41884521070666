import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../../services/auth.service";
import logo from "../../assets/logo.png";
import PasswordInput from "./PasswordInput";

const getError = (error) => {
  if (!error.response)
    return "Сервер временно недоступен. Повторите попытку позже";
  else if (error.response.status === 401) {
    var error_string = "Неверное имя или пароль";
    return error_string;
  } else return "Неизвестная ошибка. Повторите позже";
};

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: "",
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        () => {
          this.props.history.push("/");
          window.location.reload();
        },
        (error) => {
          const resMessage = getError(error);

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    return (
      <div>
        <div className="container py-5 h-100">
          <div className="row justify-content-center ">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="card" style={{ borderRadius: "1rem" }} >
                <div className="card-body p-5 text-center">
                  <div className="mx-auto p-1">
                    <img width="150" src={logo} alt="logo" />
                  </div>

                  <Form
                    onSubmit={this.handleLogin}
                    ref={(c) => {
                      this.form = c;
                    }}
                    
                  >
                    <div className="form-outline mb-4 mt-4">
                    <div className = "row" >
                      

                      <div className = "offset-1 col-10">

                      <input
                        className="form-control form-control-md"
                        name="username"
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                        required={true}
      
                      />
                      </div>
                      </div>
                      <label className="form-label" htmlFor="typeEmailX">
                        Логин
                      </label>
                    </div>

                    <div className="form-outline mb-4">
                      <PasswordInput 
                        className="form-control form-control-md"
                        name="password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                      />

                      {/* <Input
                        type="password"
                        className="form-control form-control-md"
                        name="password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                        validations={[required]}
                      /> */}

                      <label className="form-label" htmlFor="typePasswordX">
                        Пароль
                      </label>
                    </div>

                    <div className="form-check d-flex justify-content-start mb-4"></div>

                    {this.state.message && (
                      <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                          {this.state.message}
                        </div>
                      </div>
                    )}

                    <button
                      className="btn btn-dark btn-block btn-lg"
                      disabled={this.state.loading}
                    >
                      {this.state.loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Войти</span>
                    </button>

                    <CheckButton
                      style={{ display: "none" }}
                      ref={(c) => {
                        this.checkBtn = c;
                      }}
                    />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
