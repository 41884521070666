import React, { Component } from "react";
import dataService from "../../services/data.service";
import AuthService from "../../services/auth.service";
import DataService from "../../services/data.service";
import {Redirect} from "react-router-dom";
import {LoadingContent} from "../common/LoadingContent";
import EmployeeLoginModal from "../employee/EmployeeLoginModal";

class Remains extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      isLoaded: false,
      data: [],
      activePageNum: 1,
    };

    this.retrieveRemains = this.retrieveRemains.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  retrieveRemains() {
    console.log(this.state.currentUser);
    DataService.getRemains({ activePageNum: this.state.activePageNum })
      .then((response) => {
        this.setState({
          data: response.data,
          isLoaded: true,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoaded: true,
          error,
        });
      });
  }

  componentDidMount() {
    this.retrieveRemains();
  }

  onPageChange(page) {
    if (page) {
      if (this.state.activePageNum !== page) {
        this.setState({ activePageNum: page }, () => this.retrieveRemains());
      }
    }
  }

  render() {
    const { currentUser, data, isLoaded, error } = this.state;
    if (!currentUser) return <Redirect to="/login" />;
    if (!isLoaded) return <LoadingContent />;
    if (error) return <>{error.message}</>;
    if (!data.results) return <>Нет даных</>;
    console.log(data);
    if (data.results.length === 0) return <>Нет доступных остатков</>;

    return (
      <div className="col-12 col-lg-10 col-xl-11">
        <div className="table-responsive">
          <table className="table">
            <thead>
            <tr>
              <th scope="col">Номер заказа</th>
              <th scope="col">Номенклатура</th>
              <th scope="col">Количество</th>
              <th scope="col">Сумма</th>
            </tr>
            </thead>
            <tbody>
            {data.results.map((val, idx) => (
              <tr
                key={
                  this.state.data.page_count * this.state.data.current + idx
                }
              >
                <td>{val.order?.order_id}</td>
                <td>{val.item}</td>
                <td>{val.quantity}</td>
                <td>{val.sum}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>

        <nav className="mt-2 " aria-label="...">
          <ul className="pagination flex-wrap">
            <li
              className={
                `mt-1 page-item ` +
                (this.state.data.current === 1 && "disabled")
              }
            >
              <button
                className="page-link"
                onClick={() => this.onPageChange(1)}
                aria-disabled="true"
              >
                &laquo;
              </button>
            </li>

            {Array(data.page_count)
              .fill()
              .map((_, i) =>
                data.current === i + 1 ? (
                  <li key={i + 1} className="mt-1 page-item active">
                    <button className="page-link">
                      {i + 1}
                    </button>
                  </li>
                ) : (
                  <li key={i + 1} className="mt-1 page-item">
                    <button
                      className="page-link"
                      onClick={() => this.onPageChange(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                )
              )}

            <li
              className={
                `mt-1 page-item ` +
                (this.state.data.page_count === this.state.data.current &&
                  "disabled")
              }
            >
              <button
                className="page-link"
                onClick={() => this.onPageChange(this.state.data.page_count)}
              >
                &raquo;
              </button>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default Remains;
