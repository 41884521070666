import dataService from "../services/data.service";
import {CITY_FILTER_ALL, CITY_FILTER_MSK, CITY_FILTER_SPB} from "../config.consts";

export const IS_LOADING = "IS_LOADING";
export const DATA_LIST_RESPONSE = "DATA_LIST_RESPONSE";
export const DATA_ITEM_RESPONSE = "DATA_ITEM_RESPONSE";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const ON_SEARCH = "ON_SEARCH";
export const ON_SEARCH_CHANGE = "ON_SEARCH_CHANGE";
export const OPEN_DATE_SEARCH = "OPEN_DATE_SEARCH";
export const ENABLE_DATE_SEARCH = "ENABLE_DATE_SEARCH"; //
export const CHANGE_CITY_FILTER = "CHANGE_CITY_FILTER"
export const SET_FROM_DATE = "SET_FROM_DATE";
export const SET_TO_DATE = "SET_TO_DATE";
export const REMOVE_ORDER_MEDIA_FROM_STATE = "REMOVE_ORDER_MEDIA_FROM_STATE";
export const REMOVE_MEASUREMENT_MEDIA_FROM_ORDER_PAGE_STATE = "REMOVE_MEASUREMENT_MEDIA_FROM_ORDER_PAGE_STATE";
export const REMOVE_MEASUREMENT_MEDIA_FROM_STATE = "REMOVE_MEASUREMENT_MEDIA_FROM_STATE";
export const UPDATE_ORDER_MEDIA_COMMENT_IN_STATE = "UPDATE_ORDER_MEDIA_COMMENT_IN_STATE";
export const UPDATE_MEASUREMENT_MEDIA_COMMENT_IN_ORDER_STATE = "UPDATE_MEASUREMENT_MEDIA_COMMENT_IN_ORDER_STATE";
export const UPDATE_MEASUREMENT_MEDIA_COMMENT_IN_STATE = "UPDATE_MEASUREMENT_MEDIA_COMMENT_IN_STATE";


export function setFromDate(date = new Date(Date.now() - ( 3600 * 1000 * 24 * 7))) {
  return {
    type: SET_FROM_DATE,
    date: date,
  };
}

export function setToDate(date = Date.now()) {
  return {
    type: SET_TO_DATE,
    date: date,
  };
}

export function openCloseDateSearch(isOpen = false) {
  return {
    type: OPEN_DATE_SEARCH,
    isOpen: isOpen,
  };
}

export function onEnableDisableDateSearch(isEnable = false) {
  return {
    type: ENABLE_DATE_SEARCH,
    isEnable: isEnable,
  };
}

export function onChangeCityFilter(currentCityFilter) {

  var newFilter = null;

  switch (currentCityFilter) {
    case CITY_FILTER_ALL:
      newFilter = CITY_FILTER_MSK;
      break;
    case CITY_FILTER_MSK:
      newFilter = CITY_FILTER_SPB;
      break;
    case CITY_FILTER_SPB:
      newFilter = CITY_FILTER_ALL;
      break;
    default:
      newFilter = CITY_FILTER_ALL;
  }

  return {
    type: CHANGE_CITY_FILTER,
    cityFilter: newFilter,
  };
}

export function isLoading(isLoading = false) {
  return {
    type: IS_LOADING,
    isLoading: isLoading,
  };
}

export function itemsResponse(data) {
  return {
    type: DATA_LIST_RESPONSE,
    data: data,
  };
}

export function itemResponse(data, stateTreeIndex, single = false) {

    return {
      type: DATA_ITEM_RESPONSE,
      data: data,
      stateTreeIndex: stateTreeIndex,
      single: single
    };


}

export function removeOrderMediaFromState(item_id, mediaId, stateTreeIndex, indexOfMediaInStateTree) {
  return {
    type: REMOVE_ORDER_MEDIA_FROM_STATE,
    mediaId: mediaId,
    stateTreeIndex: stateTreeIndex,
  };
}

export function removeMeasurementMediaFromOrderPageState(item_id, mediaId, stateTreeIndex, indexOfMediaInStateTree) {
  return {
    type: REMOVE_MEASUREMENT_MEDIA_FROM_ORDER_PAGE_STATE,
    item_id: item_id,
    mediaId: mediaId,
    stateTreeIndex: stateTreeIndex,
    indexOfMediaInStateTree: indexOfMediaInStateTree,
  };
}

export function removeMeasurementMediaFromState(item_id, mediaId, stateTreeIndex, indexOfMediaInStateTree) {
  return {
    type: REMOVE_MEASUREMENT_MEDIA_FROM_STATE,
    item_id: item_id,
    mediaId: mediaId,
    stateTreeIndex: stateTreeIndex,
    indexOfMediaInStateTree: indexOfMediaInStateTree,
  };
}

export function changePage(newPageNum = 1) {
  return {
    type: CHANGE_PAGE,
    activePageNum: newPageNum,
  };
}

export function search() {
  return {
    type: ON_SEARCH,

  };
}

export function onChangeSearchLine(searchLine = "") {
  return {
    type: ON_SEARCH_CHANGE,
    searchLine: searchLine,
  };
}

export function updateOrderMediaCommentInOrderState(mediaId, parentId, data) {
  return {
    type: UPDATE_ORDER_MEDIA_COMMENT_IN_STATE,
    mediaId: mediaId,
    parentId: parentId,
    comment: data.comment
  }
}

export function updateMeasurementMediaCommentInOrderState(mediaId, parentId, data) {
  return {
    type: UPDATE_MEASUREMENT_MEDIA_COMMENT_IN_ORDER_STATE, //
    mediaId: mediaId,
    parentId: parentId,
    comment: data.comment
  }
}

export function updateMeasurementMediaCommentState(mediaId, parentId, data) {
  return {
    type: UPDATE_MEASUREMENT_MEDIA_COMMENT_IN_STATE,
    mediaId: mediaId,
    parentId: parentId,
    comment: data.comment
  }
}

export function fetchDataList(fetchDataFunc, search_by_line=false, search_by_date=false) {
  return (dispatch, getState) => {
    dispatch(isLoading(true));
    const data = getState().pageState;
    fetchDataFunc(data, search_by_line, search_by_date )
      .then(response => {
        return response;
      })
      .then(response => response.data)
      .then(data => {
        dispatch(isLoading(false));
        dispatch(itemsResponse(data));
      });
  };
}

export function fetchData(fetchDataFunc, id, stateTreeIndex, single=false) {
  return (dispatch, getState) => {
    fetchDataFunc(id)
      .then(response => {
        return response;
      })
      .then(response => response.data)
      .then(data => {
        dispatch(itemResponse(data, stateTreeIndex, single));
      });
  };
}

export function sendSMS(id, stateTreeIndex) {

  return (dispatch, getState) => {
    dataService.sendSMS(id).then( _ => {
        dispatch(fetchData(dataService.getOrder, id, stateTreeIndex))
      });
  };
}

export function sendFeedback(id, stateTreeIndex) {

  return (dispatch, getState) => {
    dataService.sendFeedback(id).then( _ => {
      dispatch(fetchData(dataService.getOrder, id, stateTreeIndex))
    });
  };
}

export function updateOrderMediaCommentInOrder(mediaId, parentId, data) {

  return (dispatch, getState) => {
    dataService.updateMediaComment(mediaId, data).then( _ => {
          dispatch(updateOrderMediaCommentInOrderState(mediaId, parentId, data))
    });
  };
}

export function updateMeasurementMediaCommentInOrder(mediaId, parentId, data) {

  return (dispatch, getState) => {
    dataService.updateMediaComment(mediaId, data).then( _ => {
          dispatch(updateMeasurementMediaCommentInOrderState(mediaId, parentId, data))
    });
  };
}

export function updateMeasurementMediaComment(mediaId, parentId, data) {

  return (dispatch, getState) => {
    dataService.updateMediaComment(mediaId, data).then( _ => {
          dispatch(updateMeasurementMediaCommentState(mediaId, parentId, data))
    });
  };
}



export function removeOrderMedia(mediaId, order_id, stateTreeIndex, indexOfMediaInStateTree) {

  return (dispatch, getState) => {

    dataService.removeMedia(mediaId).then( _ => {
      dispatch(removeOrderMediaFromState(order_id, mediaId, stateTreeIndex, indexOfMediaInStateTree))
      });
  };
}

export function removeMeasurementMediaFromOrderPage(mediaId, measurementId, stateTreeIndex, indexOfMediaInStateTree) {

  return (dispatch, getState) => {

    dataService.removeMedia(mediaId).then( _ => {
      dispatch(removeMeasurementMediaFromOrderPageState(measurementId, mediaId, stateTreeIndex, indexOfMediaInStateTree))
      });
  };
}

export function removeMeasurementMedia(mediaId, measurementId, stateTreeIndex, indexOfMediaInStateTree) {

  return (dispatch, getState) => {

    dataService.removeMedia(mediaId).then( _ => {
      dispatch(removeMeasurementMediaFromState(measurementId, mediaId, stateTreeIndex, indexOfMediaInStateTree))
      });
  };
}


