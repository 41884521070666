import http, { GALLERY_URL } from "../http-common";
import authHeader from "./auth-header";
import moment from "moment";
import {CITY_FILTER_MSK, CITY_FILTER_SPB} from "../config.consts";
import axios from "axios";

class DataService {

  saveOrderMedia(order_id, data) {
    let formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (key !== "files") {
        formData.append(key, value);
      }
    }

    for (let idx in data.files) {
      formData.append(`file_${idx}`, data.files[idx]);
    }

    return authHeader().then((header) => {
      return http.post(`/media/${order_id}/save_order_media/`, formData, {
        headers: header,
      });
    });
  }

  saveMeasurementMedia(measurementId, data) {
    let formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (key !== "files") {
        formData.append(key, value);
      }
    }

    for (let idx in data.files) {
      formData.append(`file_${idx}`, data.files[idx]);
    }

    return authHeader().then((header) => {
      return http.post(
        `/media/${measurementId}/save_measurement_media/`,
        formData,
        { headers: header }
      );
    });
  }

  getEmployees(data) {
    let get_params = "?";
    if (data.activePageNum) get_params += "page=" + data.activePageNum + "&";
    if (data.sortField && data.sortOrder) {
      let order_drf_string = data.sortOrder === "asc" ? "" : "-";
      get_params += "ordering=" + order_drf_string + data.sortField + "&";
    }
    if (data.filterProps) {
      for (let field in data.filterProps) {
        if (data.filterProps[field].value) {
          get_params += `${field}${data.filterProps[field].type}=${data.filterProps[field].value}&`;
        }
      }
    }

    if (data.searchInput) {
      let searchString = data.searchInput.replace(/\s/g, "+");
      get_params += "search=" + searchString;
    }

    return authHeader().then((header) => {
      return http.get("/employees/" + get_params, { headers: header });
    });
  }

  getOrders(data = { activePageNum: 1, searchLine: "" }) {
    let get_params = "?";
    if (data.activePageNum) get_params += "page=" + data.activePageNum + "&";

    if (data.isDateSearchEnabled)
      get_params +=
        "date__gte=" +
        moment(data.fromDate).format("YYYY-MM-D 00:00:00") +
        "&" +
        "date__lte=" +
        moment(data.toDate).format("YYYY-MM-D 23:59:59") +
        "&";

    switch(data.currentCityFilter) {
      case CITY_FILTER_MSK:
        get_params += `city=${CITY_FILTER_MSK.filter_text}&`;
        break;
      case CITY_FILTER_SPB:
        get_params += `city=${CITY_FILTER_SPB.filter_text}&`;
        break;
    }

    if (data.searchLine !== "") {
      let search_string = data.searchLine.replace(/\s/g, "+");
      get_params += "search=" + search_string;
    }

    return authHeader().then((header) => {
      return http.get("/orders/" + get_params, { headers: header });
    });
  }

  getMeasurements(data = { activePageNum: 1, searchLine: "" }) {
    let get_params = "?";
    if (data.activePageNum) get_params += "page=" + data.activePageNum + "&";

    if (data.isDateSearchEnabled)
      get_params +=
        "date__gte=" +
        moment(data.fromDate).format("YYYY-MM-D 00:00:00") +
        "&" +
        "date__lte=" +
        moment(data.toDate).format("YYYY-MM-D 23:59:59") +
        "&";

      switch(data.currentCityFilter) {
        case CITY_FILTER_MSK:
          get_params += `city=${CITY_FILTER_MSK.filter_text}&`;
          break;
        case CITY_FILTER_SPB:
          get_params += `city=${CITY_FILTER_SPB.filter_text}&`;
          break;
      }

    if (data.searchLine !== "") {
      let search_string = data.searchLine.replace(/\s/g, "+");
      get_params += "search=" + search_string;
    }

    return authHeader().then((header) => {
      return http.get("/measurements/" + get_params, { headers: header });
    });
  }

  getRemains(data = { activePageNum: 1 }) {
    let get_params = "?";
    if (data.activePageNum) get_params += "page=" + data.activePageNum + "&";

    return authHeader().then((header) => {
      return http.get(`/remains/` + get_params, { headers: header });
    });
  }

  getGallery(data = { prefix: '' }) {
    return authHeader().then((header) => {
      return axios.get(GALLERY_URL + `/gallery/` + data.prefix, { headers: header });
    });
  }

  getOrder(id) {
    return authHeader().then((header) => {
      return http.get("/orders/" + id + "/", { headers: header });
    });
  }

  sendSMS(id) {
    return authHeader().then((header) => {
      return http.patch(
        "/orders/" + id + "/send_sms/",
        {},
        { headers: header }
      );
    });
  }

  sendFeedback(id) {
    return authHeader().then((header) => {
      return http.patch(
        "/orders/" + id + "/send_feedback/",
        {},
        { headers: header }
      );
    });
  }

  getMeasurement(id) {
    return authHeader().then((header) => {
      return http.get("/measurements/" + id + "/", { headers: header });
    });
  }

  createUpdateUsernameManual(data) {
    return authHeader().then((header) => {
      return http.post(`/employees/${data.id}/createUpdateAccount/`, data, {
        headers: header,
      });
    });
  }

  createUpdateUsernameAuto(data) {
    return authHeader().then((header) => {
      return http.post(`/employees/${data.id}/createUpdateAccountAuto/`, data, {
        headers: header,
      });
    });
  }

  getOrderMedia(id) {
    return http.get("/order_galery/" + id + "/");
  }

  getMeasurementMedia(id) {
    return http.get("/measurement_galery/" + id + "/");
  }

  removeMedia(id) {
    return authHeader().then((header) => {
      return http.delete("/media/" + id + "/", { headers: header });
    });
  }

  getSettings(id) {
    return authHeader().then((header) => {
      return http.get("/settings/", { headers: header });
    });
  }

  updateSettings(data) {
    return authHeader().then((header) => {
      return http.put("/settings/", data, { headers: header });
    });
  }

  updateMediaComment(id, data) {
    return authHeader().then((header) => {
      return http.patch("/media/" + id + "/update_comment/", data, {
        headers: header,
      });
    });
  }
}

export default new DataService();
