import { ru } from "date-fns/locale";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
require('react-datepicker/dist/react-datepicker.css')

registerLocale("ru", ru);
function DateSearchForm(props) {
  return (
    <div className="page_structure align-items-end d-inline">
      <hr />

      <div className="mb-3 mt-3 row">
        <div className=" col-12 offset-sm-8 col-sm-4 text-center offset-xl-10 col-xl-2 text-white">
          Фильтр по дате
        </div>
      </div>

      <div className="mb-3 mt-3 row">
        <label className="col-2 offset-sm-8 offset-xl-9 col-xl-1 col-sm-1 col-form-label text-end text-white ">
          От
        </label>
        <div className="col-10 col-sm-3 col-xl-2">
          <DatePicker
            dateFormat="dd.MM.yyyy"
            locale="ru"
            onChange={(date) => {
              props.setFromDate(date);
            }}
            selected={props.fromDate}
            className={" form-control search_input text-start"}
          />
        </div>
      </div>

      <div className="mb-3 mt-3 row">
        <label className="col-2 offset-sm-8 offset-xl-9 col-xl-1 col-sm-1 col-form-label text-end text-white">
          До
        </label>
        <div className="col-10 col-sm-3 col-xl-2">
          <DatePicker
            dateFormat="dd.MM.yyyy"
            locale="ru"
            onChange={(date) => {
              props.setToDate(date);
            }}
            selected={props.toDate}
            className={"search_input form-control"}
          />
          <div class="row">
            <div className="col d-flex justify-content-between">
              <button
                className=" btn btn-sm mt-2 "
                style={{
                  background: "lightgray",
                  border: "solid 1px gray",
                  float: "right",
                }}
                onClick={() => {
                  props.setFromDate(
                    new Date(Date.now() - 3600 * 1000 * 24 * 7)
                  );
                  props.setToDate(Date.now());
                  props.onEnableDisableDateSearch(false);
                }}
              >
                Сбросить
              </button>

              <button
                className=" btn btn-sm mt-2 "
                style={{
                  background: "lightgray",
                  border: "solid 1px gray",
                  float: "right",
                }}
                onClick={() => {
                  props.onEnableDisableDateSearch(true);
                }}
              >
                Выбрать
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr />
    </div>
  );
}
export default DateSearchForm;
