import { urlsForPay } from "./config.consts";

export const kbToMb = (kb) => {
  return (kb / (1024 * 1024)).toFixed(2);
};

export const nameResize = (default_name) => {
  return default_name.length > 11
    ? default_name.slice(0, 3) + "..." + default_name.slice(-5)
    : default_name;
};

export const getPayUrlByCity = (city) => {
  if (urlsForPay.hasOwnProperty(city)) {
    return urlsForPay[city];
  } else {
    return "";
  }
};

export const parseDateTime = (datetime) => {
  var datetime_splited = datetime.split(" ");
  var date_splited = datetime_splited[0].split("-");
  var time_splited = datetime_splited[1].split(":");
  return new Date(
    date_splited[2],
    date_splited[1] - 1,
    date_splited[0],
    time_splited[0],
    time_splited[1]
  );
};

export const getDaysAgoDelta = (date) => {
  return (new Date() - date) / (24 * 60 * 60 * 1000);
};
