import React from "react";
import DateSearchForm from "./DateSearchForm";
import { allCityIcon, photoIcon, calendarIcon, mskCityIcon, searchIcon, spbCityIcon } from "./icons.consts";



const SearchLine = (props) => {
  return (
    <div className=" page_structure row  pt-2 pb-2">
      <div className="search_line_input_container ps-lg-0">
        <input
          className="form-control search_input"
          onChange={(e) => props.onChangeSearchLine(e.target.value)}
          placeholder="Поиск"
          aria-label="Search"
        />
      </div>

      <div className="search_line_buttons_container ">
        <div style={{ float: "left", width: "50px" }}>
          <button
            style={{ background: "lightgray", border: "solid 1px gray" }}
            onClick={() => props.onSearch()}
            className="btn text-break"
            type="submit"
          >
            {searchIcon}
          </button>
        </div>
        <div className="" style={{ display: "", float: "left", width: "50px" }}>
          {props.shouldUseDateSearch && (
            <button
              style={{ background: "lightgray", border: "solid 1px gray" }}
              onClick={() => props.openCloseDateSearch(!props.isDateSearchOpen)}
              className="btn text-break"
              type="submit"
            >
              {calendarIcon}
            </button>
          )}
        </div>

        <div style={{ float: "left", width: "50px" }}>
          <button
            style={{ background: "lightgray", border: "solid 1px gray" }}
            onClick={() => props.onChangeCityFilter(props.currentCityFilter)}
            className="btn ps-1 pe-1"
            type="submit"
          >
          {props.currentCityFilter.button_text}


          </button>
        </div>
      </div>

      {props.isDateSearchOpen && (
        <div>
          <DateSearchForm
            toDate={props.toDate}
            fromDate={props.fromDate}
            setToDate={props.setToDate}
            setFromDate={props.setFromDate}
            onSearch={props.onSearch}
            onEnableDisableDateSearch={props.onEnableDisableDateSearch}
            currentCityFilter={props.currentCityFilter}
            onChangeCityFilter={props.onChangeCityFilter}
          />
        </div>
      )}
    </div>
  );
};

export default SearchLine;
