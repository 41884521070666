import { allCityIcon } from "./components/common/icons.consts";

export const mimeTypes = [
  "image/gif",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/pjpeg",
  "image/webp",
  "image/heic",
  "image/heif",
  "video/mpeg",
  "video/mp4",
  "video/quicktime",
  "video/webm",
  "video/x-flv",
  "video/x-msvideo",
  "video/3gpp",
  "video/3gpp2",
  "video/x-matroska",
  "video/ogg", // chrome recognizes video/ogg as audio/ogg
  "audio/ogg"
];

export const maxFileSize = 52428800; //50miB

export const browserSupportedMime = [
  "video/mpeg", //ios +, chrome +, android, + firefox +
  "video/mp4", //ios +, chrome +, android, + firefox +
  "video/ogg",  //?
  "video/webm", //ios -, chrome +, android, + firefox + (ios не поддерживается, mac должно работать)
  "video/quicktime", //ios +, chrome -, android -, + firefox +
  "video/x-flv",
  // "video/x-matroska",
  // "video/x-msvideo",
]

export const Page404 = () => <p>Страница не найдена</p>

export const urlsForPay = { 
                            "Москва":"https://master-bobr.ru/about/about-company/#oplata_zakaza",
                            "Санкт-Петербург":"https://spb.master-bobr.ru/about/about-company/#oplata_zakaza"
                          }

export const daysForDeleteButtonVisible = 3;

export const CITY_FILTER_ALL = {
  id:0,
  button_text:allCityIcon,
  filter_text:""
};

export const CITY_FILTER_MSK = {
  id:1,
  button_text:"МСК",
  filter_text:"Москва"
};

export const CITY_FILTER_SPB = {
  id:2,
  button_text:"СПБ",
  filter_text:"Санкт-Петербург"
};