import React, {Component} from "react";
import dataService from "../../services/data.service";
import AuthService from "../../services/auth.service";
import DataService from "../../services/data.service";
import {Redirect} from "react-router-dom";
import {LoadingContent} from "../common/LoadingContent";
import EmployeeLoginModal from "../employee/EmployeeLoginModal";
import { folderImage } from "../common/icons.consts";
import FsLightbox from "fslightbox-react";

class Gallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      isLoaded: false,
      gallery: {},
      showGallery: false,
      imageNum: 1,
    };

    this.getGallery = this.getGallery.bind(this);
    this.makeCrumbs = this.makeCrumbs.bind(this);
    this.openLightboxOnSlide = this.openLightboxOnSlide.bind(this);
  }

  openLightboxOnSlide(number) {
    this.setState({
      showGallery: !this.state.showGallery,
      imageNum: number
    });
  }

  getGallery(prefix = '') {
    DataService.getGallery({prefix})
      .then((response) => {
        this.setState({
          gallery: response.data, isLoaded: true,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isLoaded: true, error,
        });
      });
  }

  makeCrumbs() {
    const crumbsList = [{
      name: 'Галерея', prefix: '',
    }];
    if (this.state.gallery?.prefix) {
      const newCrumbs = this.state.gallery.prefix.split('/');
      let prefix = '';
      newCrumbs.forEach(crumb => {
        prefix += `${crumb}/`;
        if(crumb){
          crumbsList.push({
            name: crumb, prefix: prefix,
          });
        }
      });
      console.log('asdf', crumbsList);
    }
    return <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {crumbsList.map((crumb, index) =>
          <li
            key={crumb.name}
            className={`breadcrumb-item ${(index !== crumbsList.length - 1) ? 'active' : ''}`}
            aria-current="page"
          >
            {(index !== (crumbsList.length - 1)) ? <a href="#" onClick={(e) => {
            e.preventDefault();
            this.getGallery(crumb.prefix)
          }}>{crumb.name}</a> : crumb.name}
        </li>)}
      </ol>
    </nav>;
  }

  componentDidMount() {
    this.getGallery();
  }

  render() {
    const {currentUser, gallery, isLoaded, error, showGallery, imageNum} = this.state;
    if (!currentUser) return <Redirect to="/login"/>;
    if (!isLoaded) return <LoadingContent/>;
    if (error) return <>{error.message}</>;

    return (<div className="col-12 col-lg-10 col-xl-11">
      <h1>Галерея</h1>
      {this.makeCrumbs()}
      <div className="folders row row-cols-1 row-cols-sm-2 row-cols-md-4">
        {gallery.folders.map((folder) => <div className="col mt-2" key={folder.id}>
          <button onClick={() => this.getGallery(folder.prefix)} className="folder-button">
            {folderImage}
            {folder.name}
          </button>
        </div>)}
      </div>
      <div className="items row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-xl-6">
        {gallery.items.map((item, idx) => <div key={item.id} className="col mt-3">
          <img onClick={() => this.openLightboxOnSlide(idx+1)} className="galleryImage img-fluid" src={item.url} alt={item.name}/>
        </div>)}
        <FsLightbox
          toggler={showGallery}
          sources={gallery.items.map(item => item.url)}
          slide={imageNum}
        />
      </div>
    </div>);
  }
}

export default Gallery;
