import React, { Component } from "react";
import { connect } from "react-redux";
import List from "./List";
import {
  fetchDataList,
  changePage,
  openCloseDateSearch,
  onEnableDisableDateSearch,
  onChangeSearchLine,
  onChangeCityFilter,
  setFromDate, 
  setToDate
} from "../../actions/data.actions";
import { isEmpty } from "lodash";
import Paginator from "./Paginator";
import SearchLine from "./SearchLine";
import { LoadingContent } from "./LoadingContent";

class DataPage extends Component {

  componentDidMount() {
    this.props.getData();
  }

  render() {
    return (
      <div className="col-12 col-lg-10 col-xl-11">
        {this.props.showSearch && (
          <SearchLine
            searchLine={this.props.pageState.searchLine}
            onSearch={this.props.onSearch}
            onChangeSearchLine={this.props.onChangeSearchLine}
            shouldUseDateSearch={true}
            isDateSearchOpen={this.props.pageState.isDateSearchOpen}
            openCloseDateSearch={this.props.openCloseDateSearch}
            onEnableDisableDateSearch={this.props.onEnableDisableDateSearch}
            fromDate={this.props.pageState.fromDate}
            toDate={this.props.pageState.toDate}
            setFromDate={this.props.setFromDate}
            setToDate={this.props.setToDate}

            currentCityFilter={this.props.pageState.currentCityFilter}
            onChangeCityFilter={this.props.onChangeCityFilter}
          />
        )}

        {this.props.dataHeader && this.props.dataHeader}

        {this.props.pageState.isLoading ? (
          <LoadingContent />
        ) : (
          !isEmpty(this.props.data) && (
            <div>
              <List
                data={this.props.data.results}
                itemComponent={this.props.itemComponent}
              />
              {this.props.pagination && (
                <Paginator
                  pageSize={this.props.data.page_size}
                  pageCount={this.props.data.page_count}
                  count={this.props.data.count}
                  current={this.props.pageState.activePageNum}
                  onPageChange={this.props.onPageChange}
                />
              )}
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.data,
    pageState: state.pageState,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getData: () => {
      dispatch(fetchDataList(ownProps.fetchDataFunc));
    },

    onPageChange: (newPageNum) => {
      dispatch(changePage(newPageNum));
      dispatch(fetchDataList(ownProps.fetchDataFunc));
    },

    onSearch: () => {
      dispatch(changePage(1));
      dispatch(fetchDataList(ownProps.fetchDataFunc));
    },

    onChangeSearchLine: (newSearchLine) => {
      dispatch(onChangeSearchLine(newSearchLine));
      dispatch(changePage(1));
    },

    openCloseDateSearch: (isOpen) => {
      dispatch(openCloseDateSearch(isOpen));
    },

    onEnableDisableDateSearch: (isEnable) => {
      dispatch(onEnableDisableDateSearch(isEnable));
      dispatch(changePage(1));
      dispatch(fetchDataList(ownProps.fetchDataFunc));
    },

    onChangeCityFilter: (filterCity) => {
      dispatch(onChangeCityFilter(filterCity));
      dispatch(changePage(1));
      dispatch(fetchDataList(ownProps.fetchDataFunc));
    },

    setFromDate: (date) => {
      dispatch(setFromDate(date));
    },

    setToDate: (date) => {
      dispatch(setToDate(date));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataPage);
