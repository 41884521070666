import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchData } from "../../actions/data.actions";
import "../../components/styles.css";
import { maxFileSize, mimeTypes } from "../../config.consts";
import dataService from "../../services/data.service";
import { kbToMb, nameResize } from "../../utils";
import {cloudIcon, photoIcon, videoIcon} from "../common/icons.consts";

class MediaDownloader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      fileValidErrors: [],
      isUploading: false,
      isUploadError: false,
    };

    this.onChangesFiles = this.onChangesFiles.bind(this);
    this.submitMedia = this.submitMedia.bind(this);
    this.clearFiles = this.clearFiles.bind(this);
  }

  clearFiles = () => {
    this.setState({
      files: [],
      fileValidErrors: [],
    });
  };

  onChangesFiles = ({ target }) => {
    if (target.files && target.files !== undefined) {
      var newFiles = [];
      var newFileValidErrors = [];
      Array.from(target.files).forEach((file) => {
        if (file.size > maxFileSize) {
          newFileValidErrors.push("Файл " + file.name + " слишком большой.");
        } else if (mimeTypes.indexOf(file.type) === -1) {
          newFileValidErrors.push(
            "Файл " + file.name + " имеет неподдерживаемое расширение.\n"
          );
        } else {
          newFiles.push(file);
        }
      });

      this.setState({
        files: [...this.state.files, ...newFiles],
        fileValidErrors: newFileValidErrors,
      });
    }
    target.value = "";
  };

  submitMedia = (e) => {
    e.preventDefault();

    var data = {
      ...this.props.payload,
      files: this.state.files,
    };
    this.setState({
      isUploading: true,
    });

    if (this.props.payload.orderId) {
      dataService
        .saveOrderMedia(this.props.payload.orderId, data)
        .then(() => {
          this.setState({
            files: [],
            fileValidErrors: [],
            isUploading: false,
          });
          this.props.updateItem();
        })
        .catch((err) => {
          this.setState({ isUploadError: true });
        });
    } else if (this.props.payload.measurementId) {
      dataService
        .saveMeasurementMedia(this.props.payload.measurementId, data)
        .then(() => {
          this.setState({
            files: [],
            fileValidErrors: [],
            isUploading: false,
          });
          this.props.updateItem();
        })
        .catch((err) => {
          this.setState({ isUploadError: true });
        });
    }
  };

  componentDidMount() {}

  render() {
    const mediaDownloadForm = (
      <form encType="multipart/form-data" className="h-100 ">
        <label className=" align-items-center media_thumbnail_card text-center">
          <input
            type="file"
            className="media_thumbnail_card"
            onInput={this.onChangesFiles}
            multiple
          />
          {cloudIcon}
        </label>
      </form>
    );



    const fileList = (
      <div className="row">
        <div className="">
          <div className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-2 mt-4">
              <div className="text-muted">
                Новых файлов: {this.state.files.length}
              </div>
            </div>
          </div>

          <ol className="mt-2 list-group list-group-numbered">
            {this.state.files.map((file, idx) => (
              <li
                key={idx}
                className="list-group-item d-flex justify-content-between align-items-start "
              >
                <div className="ms-1 me-auto">
                  <div className="fw-bold "> {nameResize(file.name)}</div>
                </div>

                <small className="text-muted ps-2 pe-2">
                  ~{kbToMb(file.size)}мб
                </small>
                <span className="badge bg-primary rounded-pill">
                  {file.type.includes("video") ? videoIcon : photoIcon}
                </span>
              </li>
            ))}
          </ol>
        </div>
      </div>
    );

    const fileErrorsList = (
      <div className="row">
        <div className="">
          <div className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto mt-4">
              <div className="text-muted">
                Не будут загружены: {this.state.fileValidErrors.length}
              </div>
            </div>
          </div>
          <ol className="mt-2 list-group list-group-numbered">
            {this.state.fileValidErrors.map((err, idx) => (
              <li
                key={idx}
                className="list-group-item d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <small className="text-muted text-break">{err}</small>
                </div>
                <span className="badge bg-danger rounded-pill">Ошибка</span>
              </li>
            ))}
          </ol>
        </div>
        <div className="col-1"></div>
      </div>
    );

    const uploadResetButtons = (
      <div className="d-flex justify-content-center align-items-center">
        <button
          className=" mt-2 btn btn-outline-secondary"
          onClick={this.submitMedia}
        >
          Добавить
        </button>
        <button
          className="mt-2 ms-2 btn btn-outline-secondary"
          onClick={this.clearFiles}
        >
          Cброс
        </button>
      </div>
    );

    const fileUploadingState = (
      <div className="d-flex justify-content-center align-items-center">
        <button
          className="btn  btn-outline-secondary mt-2"
          type="button"
          disabled
        >
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Выполняется загрузка...
        </button>
      </div>
    );

    const uploadError = (
      <div className="row">
        <div className="offset-1 col">
          <div className="d-flex justify-content-center align-items-center">
            <div className="alert  alert-danger mt-2">
              <span className=" spinner-border-sm"></span>
              Ошибка при загрузке
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div>
        {mediaDownloadForm}

        {this.state.fileValidErrors.length !== 0 && fileErrorsList}
        {this.state.files.length !== 0 && fileList}

        {this.state.files.length !== 0 &&
          this.state.isUploading &&
          fileUploadingState}
        {this.state.files.length !== 0 &&
          !this.state.isUploading &&
          uploadResetButtons}

        {this.state.isUploadError && uploadError}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  if (ownProps.payload.hasOwnProperty("orderId")) {
    return {
      updateItem: () => {
        dispatch(
          fetchData(
            dataService.getOrder,
            ownProps.payload.orderId,
            ownProps.indexInStateTree
          )
        );
      },
    };
  }
  if (ownProps.payload.hasOwnProperty("measurementId")) {
    return {
      updateItem: () => {
        dispatch(
          fetchData(
            dataService.getMeasurement,
            ownProps.payload.measurementId,
            ownProps.indexInStateTree
          )
        );
      },
    };
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaDownloader);
