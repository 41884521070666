import React, { Component } from "react";
import { default as Modal, default as ReactModal } from "react-modal";
import dataService from "../../services/data.service";

const defaultState = {
  isOpen: false,
  employee: {},
  manualLoginFormShow: false,
  autoLoginFormShow: false,
  loading: false,
};

const defaultFormState = {
  usernameInput: "",
  passwordInput: "",
  usernameAutoInput: "",
  passwordAutoInput: "",
  formError: false,
  formSuccess: false,
};

class EmployeeLoginModal extends Component {
  constructor(props) {
    super(props);

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.onClickShowFormManual = this.onClickShowFormManual.bind(this);
    this.onClickShowFormAuto = this.onClickShowFormAuto.bind(this);
    this.onChangeLoginInput = this.onChangeLoginInput.bind(this);
    this.onChangePasswordInput = this.onChangePasswordInput.bind(this);
    this.createUpdateUsernameManual =
      this.createUpdateUsernameManual.bind(this);
    this.createUpdateUsernameAuto = this.createUpdateUsernameAuto.bind(this);
    this.state = {
      ...defaultState,
    };

    Modal.setAppElement("#root");
  }

  onClickShowFormManual() {
    if (this.state.manualLoginFormShow === this.state.autoLoginFormShow) {
      this.setState({
        manualLoginFormShow: true,
        ...defaultFormState,
      });
      return;
    }

    if (this.state.manualLoginFormShow) {
      this.setState({
        manualLoginFormShow: false,
        ...defaultFormState,
      });
      return;
    }

    if (this.state.autoLoginFormShow) {
      this.setState({
        manualLoginFormShow: true,
        autoLoginFormShow: false,
        ...defaultFormState,
      });
      return;
    }
  }

  onClickShowFormAuto() {
    if (this.state.manualLoginFormShow === this.state.autoLoginFormShow) {
      this.setState({
        autoLoginFormShow: true,
        ...defaultFormState,
      });
      return;
    }

    if (this.state.autoLoginFormShow) {
      this.setState({
        autoLoginFormShow: false,
        ...defaultFormState,
      });
      return;
    }

    if (this.state.manualLoginFormShow) {
      this.setState({
        manualLoginFormShow: false,
        autoLoginFormShow: true,
        ...defaultFormState,
      });
      return;
    }
  }

  onChangeLoginInput(e) {
    this.setState({
      usernameInput: e.target.value,
    });
  }

  onChangePasswordInput(e) {
    this.setState({
      passwordInput: e.target.value,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      return true;
    }

    if (nextProps.isOpen !== this.props.isOpen) {
      return true;
    }

    if (nextState.manualLoginFormShow !== this.state.manualLoginFormShow) {
      return true;
    }

    if (nextState.autoLoginFormShow !== this.state.autoLoginFormShow) {
      return true;
    }
    return false;
  }

  createUpdateUsernameManual() {
    let username = this.state.usernameInput;
    username = username.replace(/\s+/g, "");

    var data = {
      id: this.state.employee.id,
      username: username,
      password: this.state.passwordInput,
    };
    this.setState({ loading: true });

    dataService
      .createUpdateUsernameManual(data)
      .then((resp) => {
        var employee = { ...this.state.employee };
        employee.user = { username: username };

        this.setState(
          {
            formSuccess: true,
            formError: null,
            loading: false,
            employee: employee,
          },
          () =>
            this.state.handleToUpdate({
              id: this.state.employee.id,
              username: this.state.usernameInput,
              rowNum: this.state.rowNum,
            })
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
          formError: err.response.data.details
            ? err.response.data.details
            : "Ошибка запроса",
          formSuccess: false,
        });
      });
  }

  createUpdateUsernameAuto() {
    var data = {
      id: this.state.employee.id,
    };
    this.setState({ loading: true });
    dataService
      .createUpdateUsernameAuto(data)
      .then((resp) => {
        var employee = { ...this.state.employee };
        employee.user = { username: resp.data.username };

        this.setState(
          {
            usernameAutoInput: resp.data.username,
            passwordAutoInput: resp.data.password,
            formSuccess: true,
            formError: null,
            employee: employee,
            loading: false,
          },
          () =>
            this.state.handleToUpdate({
              id: this.state.employee.id,
              username: resp.data.username,
              rowNum: this.state.rowNum,
            })
        );
      })
      .catch((err) => {
        this.setState({
          formError: err.response.data.details
            ? err.response.data.details
            : "Ошибка запроса",
          formSuccess: false,
          loading: false,
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({
        isOpen: true,
        employee: this.props.employee,
        usernameInput: this.props.employee.user
          ? this.props.employee.user.username
          : "",
        handleToUpdate: this.props.handleToUpdate,
        rowNum: this.props.rowNum,
      });
    }
  }

  successMsgUI(msg = "Учетная запись была успешно обновлена") {
    return <div className="alert alert-success text-center">{msg}</div>;
  }

  handleCloseModal() {
    this.setState({
      ...defaultState,
    });
  }

  componentDidMount() {}

  render() {
    return (
      <ReactModal
        isOpen={this.state.isOpen}
        overlayClassName=""
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        className="modal-dialog"
        tabIndex="-1"
        style={{ overlay: { zIndex: "4" }, content: {} }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Управление учетной записью</h5>
            <button
              type="button"
              className="btn-close"
              onClick={this.handleCloseModal}
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            <div className="p-2">
              <p>ФИО: {this.state.employee.name}</p>
              {this.state.employee.user ? (
                <p>Логин: {this.state.employee.user.username}</p>
              ) : (
                <p> Учетная запись для пользователя не создана </p>
              )}
            </div>
            <div
              className="row"
              role="group"
              aria-label="Basic outlined example"
            >
              <div className="col-12 offset-md-1 col-md-5">
                <button
                  onClick={this.onClickShowFormManual}
                  type="button"
                  className={
                    "btn py-3 btn-outline-primary w-100 mt-1 " +
                    (this.state.manualLoginFormShow ? "active" : "")
                  }
                >
                  Установить вручную
                </button>
              </div>

              <div className="col-12 col-md-5">
                <button
                  onClick={this.onClickShowFormAuto}
                  type="button "
                  className="btn  py-3 btn-outline-primary w-100 mt-1 "
                >
                  Установить автоматически
                </button>
              </div>
            </div>

            {this.state.manualLoginFormShow && (
              <div className="mt-3 card p-3">
                <div className="mb-3">
                  <label for="username_manual" className="form-label">
                    Логин
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username_manual"
                    aria-describedby="usernameHelp"
                    defaultValue={this.state.usernameInput}
                    onChange={this.onChangeLoginInput}
                  />
                </div>
                <div className="mb-3">
                  <label for="password_manual" className="form-label">
                    Пароль
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password_manual"
                    onChange={this.onChangePasswordInput}
                  />
                  <div id="emailHelp" className="form-text">
                    В целях безопасности, текст существующего пароля не может
                    быть отображен.
                  </div>
                </div>

                {this.state.formSuccess && this.successMsgUI()}

                {this.state.formError && (
                  <div className="alert alert-danger text-center">
                    {this.state.formError}
                  </div>
                )}

                <div className="text-center">
                  {this.state.loading ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        ariaHidden="true"
                      ></span>
                      Подождите...
                    </button>
                  ) : (
                    <button
                      onClick={this.createUpdateUsernameManual}
                      className="btn btn-primary"
                    >
                      Сохранить
                    </button>
                  )}
                </div>
              </div>
            )}

            {this.state.autoLoginFormShow && (
              <div className="mt-3 card p-3">
                <div className="mb-3">
                  <label for="username_auto" className="form-label">
                    Логин
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    id="username_auto"
                    aria-describedby="usernameAutoHelp"
                    defaultValue={this.state.usernameAutoInput}
                  />
                </div>

                <div className="mb-3">
                  <label for="password_auto" className="form-label">
                    Пароль
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    id="password_auto"
                    defaultValue={this.state.passwordAutoInput}
                  />
                </div>

                {this.state.formSuccess && this.successMsgUI()}

                {this.state.formError && (
                  <div className="alert alert-danger text-center">
                    {this.state.formError}
                  </div>
                )}

                {this.state.loading ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Подождите...
                  </button>
                ) : (
                  <button
                    onClick={this.createUpdateUsernameAuto}
                    className="btn btn-primary"
                  >
                    Сгенерировать и сохранить
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.handleCloseModal}
            >
              Закрыть
            </button>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default EmployeeLoginModal;
