import React, { Component } from "react";
import { isAndroid, isChrome, isMobileSafari } from "react-device-detect";
import Modal from "react-modal";
import placeholderImage from "../../assets/ph.png";
import { browserSupportedMime, daysForDeleteButtonVisible } from "../../config.consts";
import authService from "../../services/auth.service";
import { getDaysAgoDelta, parseDateTime } from "../../utils";
import {
  chevronCompactLeftIcon,
  chevronCompactRightIcon,
  closeIcon,
  fullScreenIcon,
  trashIcon,
} from "../common/icons.consts";
import MediaComment from "./MediaComment";

const defaultState = {
  initialX: 0,
  finalX: 0,
  initialY: 0,
  finalY: 0,
  swipping: false,
};

class MediaModal extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.swipeStart = this.swipeStart.bind(this);
    this.swipeEnd = this.swipeEnd.bind(this);
    this.swipeMove = this.swipeMove.bind(this);

    this.state = {
      ...defaultState,
      isOpen: false,
    };

    Modal.setAppElement("#root");
  }

  swipeStart = (e) => {
    this.setState({
      initialX: e.touches[0].clientX,
      initialY: e.touches[0].clientY,
    });
  };

  swipeMove = (e) => {
    this.setState({
      finalX: e.touches[0].clientX,
      finalY: e.touches[0].clientY,
      swipping: true,
    });
  };

  swipeEnd = (e) => {
    var deltaX = this.state.finalX - this.state.initialX;
    var deltaY = this.state.finalY - this.state.initialY;

    var isSwipe = true;

    if (this.state.finalX === 0) isSwipe = false;

    if (Math.abs(deltaX) < 10) isSwipe = false;
    if (deltaY > 50) isSwipe = false;

    if (isSwipe) {
      if (deltaX > 0) this.toLeft();

      if (deltaX < 0) this.toRight();
    }

    this.setState({
      ...defaultState,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.myRef.current) {
      this.myRef.current.load();
    }

    if (prevProps.mediaArray.length !== this.props.mediaArray.length) {
    }
  }

  handleCloseModal(e) {
    const closableDivIds = [
      "close_cross_svg",
      "close_cross_path",
      "modal_image_overlay",
    ];

    if (!closableDivIds.includes(e.target.id)) return;

    this.props.closeModalCB();
  }

  leftButtonIsActive = () =>
    this.props.currentMediaIndex === 0 ? false : true;

  rightButtonIsActive = () =>
    this.props.currentMediaIndex === this.props.mediaArray.length - 1
      ? false
      : true;

  toLeft() {
    if (this.props.currentMediaIndex > 0)
      this.props.changeSelectedMediaCB(
        this.props.mediaArray[this.props.currentMediaIndex - 1]
      );
  }

  toRight() {
    if (this.props.currentMediaIndex + 1 < this.props.mediaArray.length)
      this.props.changeSelectedMediaCB(
        this.props.mediaArray[this.props.currentMediaIndex + 1]
      );
  }

  componentDidMount() {
    this.setState({
      currentMediaIndex: this.props.currentMediaIndex,
    });
  }

  unsupportedMsg = (errStr, shouldDownload = true) => {
    return (
      <div className="modal_image_medium_img modal_image_content_overlay_img">
        <div className="unsuppported_msg_text">
          <p> {errStr} </p>
          {shouldDownload && (
            <a
              className="btn btn-light"
              href={this.props.mediaArray[this.props.currentMediaIndex].video}
            >
              Скачать
            </a>
          )}
        </div>
      </div>
    );
  };

  getVideo = () => {
    if (
      browserSupportedMime.includes(
        this.props.mediaArray[this.props.currentMediaIndex].mime_type
      )
    ) {
      if (
        this.props.mediaArray[this.props.currentMediaIndex].mime_type ===
          "video/webm" &&
        isMobileSafari
      )
        return this.unsupportedMsg(
          `Видео формата ${
            this.props.mediaArray[this.props.currentMediaIndex].mime_type
          } на данный момент не поддерживается на платформе IOS.`
        );

      if (
        this.props.mediaArray[this.props.currentMediaIndex].mime_type ===
          "video/quicktime" &&
        (isChrome || isAndroid)
      )
        return this.unsupportedMsg(
          `Видео формата ${
            this.props.mediaArray[this.props.currentMediaIndex].mime_type
          } на данный момент не поддерживается в браузерах Chrome.`
        );

      if (
        this.props.mediaArray[this.props.currentMediaIndex].mime_type ===
          "video/ogg" &&
        (isMobileSafari || isAndroid)
      )
        return this.unsupportedMsg(
          `Видео формата ${
            this.props.mediaArray[this.props.currentMediaIndex].mime_type
          } на данный момент не поддерживается в мобильных браузерах.`
        );
      return (
        <video
          controls
          ref={this.myRef}
          playsInline
          preload="metadata"
          className="modal_image_content_overlay_video"
        >
          <source
            playsInline
            src={this.props.mediaArray[this.props.currentMediaIndex].file}
            type={this.props.mediaArray[this.props.currentMediaIndex].mime_type}
          />
        </video>
      );
    } else {
      return this.unsupportedMsg(
        `Видео формата ${
          this.props.mediaArray[this.props.currentMediaIndex].mime_type
        } на данный момент не поддерживается для воспроизведения браузерами.`
      );
    }
  };

  getImage = () => {
    return (
      <img
        className="modal_image_medium_img modal_image_content_overlay_img"
        src={
          this.props.mediaArray[this.props.currentMediaIndex].medium_img
            ? this.props.mediaArray[this.props.currentMediaIndex].medium_img
            : this.props.mediaArray[this.props.currentMediaIndex].file
        }
        alt={
          this.props.mediaArray[this.props.currentMediaIndex].medium_img
            ? this.props.mediaArray[this.props.currentMediaIndex].medium_img
            : this.props.mediaArray[this.props.currentMediaIndexs].file
        }
        onLoad={() => {}}
      />
    );
  };

  removeButton = () => {
    var user = authService.getCurrentUser();

    if (!user.is_superuser) {
      var pubDate = parseDateTime(
        this.props.mediaArray[this.props.currentMediaIndex].pub_date
      );
      if (getDaysAgoDelta(pubDate) >= daysForDeleteButtonVisible) {
        return "";
      }
    }

    return (
      <span
        className="modal_image_icon_menu btn btn-sm btn-outline-danger p-1 pt-1 mt-2 mb-2 me-4"
        onClick={() => {
          this.props.removeFunc(
            this.props.mediaArray[this.props.currentMediaIndex].id,
            this.props.parentRowId,
            this.props.currentMediaIndex
          );
        }}
      >
        {trashIcon}
        Удалить
      </span>
    );
  };

  fullScreenButton = () => {
    return (
      <a
        className="modal_image_icon_menu btn btn-sm btn-outline-dark p-1 pt-1 mt-2 mb-2 me-4"
        target="_blank" 
        rel="noreferrer"
        href={this.props.mediaArray[this.props.currentMediaIndex].file}
      >
        {fullScreenIcon} Полный экран
      </a>
    );
  };

  closeButton = () => {
    return this.props.isHideCloseButton ? (
      ""
    ) : (
      <span className="modal_image_icon_menu"> {closeIcon} </span>
    );
  };

  showVideo = () => {
    return this.props.mediaArray[this.props.currentMediaIndex].processed
      ? this.getVideo()
      : this.unsupportedMsg(
          `В настоящий момент файл обрабатывается на сервере. Видео будет доступно через несколько секунд`,
          false
        );
  };

  showImage = () => {
    return this.props.mediaArray[this.props.currentMediaIndex].processed
      ? this.getImage()
      : this.unsupportedMsg(
          `В настоящий момент файл обрабатывается на сервере. Изображение будет доступно через несколько секунд`,
          false
        );
  };

  getModal = () => {
    return (
      <div>
        <div className="modal_image_container" onClick={this.handleCloseModal}>
          <div id="modal_image_overlay" className="modal_image_overlay">
            <div id="modal_image_content" className="modal_image_content">
              <div className="modal_image_header ">
                {this.closeButton()}
                {this.fullScreenButton()}
                {this.props.isShowRemoveButton && this.removeButton()}

                <span className="modal_image_caption"></span>
              </div>

              <div
                id="modal_image_content_overlay"
                className="modal_image_content_overlay"
                onTouchStart={this.swipeStart}
                onTouchMove={this.swipeMove}
                onTouchEnd={this.swipeEnd}
              >
                <button
                  onClick={() => {
                    this.toLeft();
                  }}
                  className="modal_left_button"
                  disabled={!this.leftButtonIsActive()}
                >
                  {chevronCompactLeftIcon}
                </button>

                <button
                  onClick={() => {
                    this.toRight();
                  }}
                  className="modal_right_button"
                  disabled={!this.rightButtonIsActive()}
                >
                  {chevronCompactRightIcon}
                </button>

                {this.props.mediaArray[
                  this.props.currentMediaIndex
                ].mime_type.includes("image")
                  ? this.showImage()
                  : this.showVideo()}
              </div>

              <div className="modal_image_footer">
                <MediaComment
                  comment={
                    this.props.mediaArray[this.props.currentMediaIndex].comment
                  }
                  mediaId={
                    this.props.mediaArray[this.props.currentMediaIndex].id
                  }
                  updateCommentFunc={this.props.updateCommentFunc}
                  parentRowId={this.props.parentRowId}
                  isCommentEditable={this.props.isCommentEditable}
                />

                <div className="modal_galery">
                  <div className="d-flex flex-wrap justify-content-center ">
                    {this.props.mediaArray.map((media, idx) => (
                      <div
                        className="flex-column p-1"
                        key={media.file + idx}
                        abs={media.file + idx}
                      >
                        <div
                          className="card"
                          style={
                            media.id ===
                            this.props.mediaArray[this.props.currentMediaIndex]
                              .id
                              ? { border: "solid 2px red" }
                              : {}
                          }
                        >
                          <div
                            // style={{ height: "10px", width: "10px" }}
                            className="card-body p-1"
                            style={{ objectFit: "cover" }}
                          >
                            <img
                              style={{ width: "auto", height: "5vh" }}
                              onClick={() => {
                                this.props.changeSelectedMediaCB(media);
                              }}
                              src={
                                media.thumbnail
                                  ? media.thumbnail
                                  : placeholderImage
                              }
                              alt={media.file}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.mediaArray.length === 0)
      return (
        <div className="d-flex flex-wrap justify-content-start justify-content-lg-center">
          Медиа-файлы отсутствуют
        </div>
      );

    return this.props.isOpen && this.props.currentMediaIndex !== -1 ? (
      this.getModal()
    ) : (
      <div> </div>
    );
  }
}

export default MediaModal;
