import React from "react";

const Paginator = (props) => {
  return (
    <nav className="mt-2" aria-label="...">
      <ul className="pagination flex-wrap">
        <li className={`mt-1 page-item ` + (props.current === 1 && "disabled")}>
          <button
            className="page-link"
            onClick={() => props.onPageChange(1)}
            aria-disabled="true"
          >
            &laquo;
          </button>
        </li>

        {
            Array(props.pageCount)
            .fill()
            .map((_, i) =>
                props.current === i + 1 ? (
                <li key={i + 1} className="mt-1 page-item active">
                    <button className="page-link">
                    {i + 1}
                    </button>
                </li>
                ) : (
                <li key={i + 1} className="page-item mt-1">
                    <button
                    className="page-link"
                    onClick={() => props.onPageChange(i + 1)}
                    >
                    {i + 1}
                    </button>
                </li>
                )
            )
          }

        <li
          className={
            `mt-1 page-item ` +
            (props.pageCount === props.current && "disabled")
          }
        >
          <button
            className="page-link"
            href={"#"}
            onClick={() => props.onPageChange(props.pageCount)}
          >
            &raquo;
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Paginator;
