import Modal from "react-modal";
import React, { Component } from "react";
import QRCode from "react-qr-code";

const defaultState = {
  isOpen: false,
  isGenerated: false,
};

class QRModal extends Component {
  constructor(props) {
    super(props);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);

    this.state = {
      ...defaultState,
    };

    Modal.setAppElement("#root");
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.isOpen !== this.state.isOpen) {
      return true;
    }

    if (nextState.isGenerated !== this.state.isGenerated) {
      return true;
    }

    if (nextProps !== this.props) {
      return true;
    }

    return false;
  }

  getQR() {
    if (this.state.isGenerated) {
      return (
        <div className="qr_modal_img">
          {this.props.title !== null ? this.props.title : ""}

          <QRCode
            value={this.props.value}
            size={320}
            title={this.props.title}
          />
        </div>
      );
    } else {
      return (
        <div className="qr_modal_img">
          <div
            className="spinner-border"
            style={{ width: "5rem", height: "5rem", margin: "0 auto" }}
            role="status"
          >
            <span className="sr-only"></span>
          </div>
        </div>
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isGenerated !== this.state.isGenerated) {
      return true;
    }
  }

  handleCloseModal(e) {
    const closableDivIds = [
      "qr_modal_overlay",
    ];

    if (!closableDivIds.includes(e.target.id)) return;

    this.setState({
      ...defaultState,
    });
  }

  handleOpenModal() {
    this.setState(
      {
        isOpen: true,
      },
      () => {
        new Promise((r) => setTimeout(r, 10)).then(() => {
          this.setState({ isGenerated: true });
        });
      }
    );
  }

  componentDidMount() {}

  getModal = () => {
    return (
      <div>
        <div className="qr_modal_container" onClick={this.handleCloseModal}>
          <div id="qr_modal_overlay" className="qr_modal_overlay">
            <div id="qr_modal_content" className="qr_modal_content">
              <div
                id="qr_modal_media_owerlay "
                className="qr_modal_media_owerlay"
              >
                {this.props.value !== null && this.props.value !== ""
                  ? this.getQR()
                  : "QR отсутствует"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.state.isOpen ? (
      this.getModal()
    ) : (
      <button onClick={this.handleOpenModal} className={this.props.styles}>
        {this.props.buttonText}
      </button>
    );
  }
}

export default QRModal;
