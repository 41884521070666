import React, { Component } from "react";
import dataService from "../../services/data.service";
import { LoadingContent } from "../common/LoadingContent";

export default class AdminSettingsPage extends Component {
  constructor(props) {
    super(props);
    this.onChangeDaysOfVisibility = this.onChangeDaysOfVisibility.bind(this);
    this.onSendNewSettings = this.onSendNewSettings.bind(this);

    this.state = {
      loaded: false,
      daysOfVisibility: 0,
      isError: false,
      errorMsg: "",
      success: false,
    };
  }

  onChangeDaysOfVisibility(e) {
    this.setState({
      daysOfVisibility: e.target.value,
    });
  }

  onSendNewSettings() {
    dataService
      .updateSettings({
        days_of_visibility: this.state.daysOfVisibility,
      })
      .then(() => {
        this.setState({
          loaded: true,
          success: true,
        });
      })
      .catch(() => {
        this.setState({
          loaded: true,
          isError: true,
          errorMsg: "Не удалось применить новые настройки",
        });
      });
  }

  componentDidMount() {
    this.setState({
      loaded: false,
    });

    dataService
      .getSettings()
      .then((resp) => {
        this.setState({
          loaded: true,
          isError: false,
          daysOfVisibility: resp.data.days_of_visibility,
        });
      })
      .catch(() => {
        this.setState({
          loaded: true,
          isError: true,
          errorMsg: "Не удалось получить список настроек",
        });
      });
  }

  render() {
    if (!this.state.loaded) return <LoadingContent />;

    if (this.state.isError)
      return (
        <div className="col-12 col-lg-11 col-xl-11">
          <div className="container  card mt-5 py-5 ">
            <div className="mb-3 mx-auto ">{this.state.errorMsg}</div>
          </div>
        </div>
      );

    return (
      <div className="col-12 col-lg-11 col-xl-11">
        <div className="container  card mt-5 py-5 ">
          <div className="mb-3 mx-auto">
            <label className="form-label">Видимость заказов в днях: </label>
            <input
              type="number"
              min="0"
              max="36500"
              className="ms-3"
              value={this.state.daysOfVisibility}
              onChange={this.onChangeDaysOfVisibility}
              required={true}
            />
          </div>

          <div className="mb-3 row text-center">
            <button
              onClick={this.onSendNewSettings}
              className="btn btn-dark admin_settings_submit_btn"
            >
              Сохранить
            </button>
          </div>

          {this.state.success && (
            <div className="mt-3 mx-auto text-center alert alert-success w-50">
              Изменения успешно сохранены!
            </div>
          )}
        </div>
      </div>
    );
  }
}
