import React from "react";

const List = (props) => {
  const Item = props.itemComponent;

  if (props.data.length === 0) {
    return (
      <div className="row d-flex justify-content-center align-items-center h-100 mt-5 mb-5">
        Записи отсутствуют
      </div>
    );
  } else {
    return (
      <div>
        {props.data.map((item, idx) => (
          <Item key={idx} idx={idx} />
        ))}
      </div>
    );
  }
};

export default List;
