export const MeasurementsPageHeader = (
  <div
    className="card  mt-2 d-none d-lg-block"
    style={{ border: "solid 2px black" }}
  >
    <div className="card-body text-center">
      <div className="row">
        <div className="col-7">
          <div className="row">
            <div className="col-2"> Дата </div>
            <div className="col-2"> ФИО </div>
            <div className="col-2"> № Заказа </div>
            <div className="col-2"> Адрес </div>
            <div className="col-2 "> Город </div>
            <div className="col-2 "> QR оплаты </div>
          </div>
        </div>

        <div className="col-5">
          <div className="row">
            <div className="col-4 "> Фото </div>
            <div className="col-4 "> Видео </div>
            <div className="col-4 "> Загрузить </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
