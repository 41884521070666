import React, { Component } from "react";
import dataService from "../../services/data.service";
import DataPage from "../common/DataPage";
import MeasurementItem from "./MeasurementItem";
import { MeasurementsPageHeader } from "./MeasurementsPageHeader";


class Orders extends Component {
  render() {
    return (
      <DataPage
        pagination={true}
        showSearch={true}
        dataHeader={MeasurementsPageHeader}
        fetchDataFunc={dataService.getMeasurements}
        itemComponent={MeasurementItem}
      />
    );
  }
}

export default Orders;
