import React, { Component } from "react";
import placeholderImage from "../../assets/ph.png";
import "../../components/styles.css";
import { arrowLeftIcon, arrowRightIcon } from "../common/icons.consts";
import MediaModal from "./MediaModal";

class MediaGalery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftMediaArray: [],
      rightMediaArray: [],
      displayedMediaArray: [],
      allMediaArray: [],
      isModalOpen: false,
      selectedMedia: null,
    };

    this.toRight = this.toRight.bind(this);
    this.toLeft = this.toLeft.bind(this);
    this.prepareStateForMedia = this.prepareStateForMedia.bind(this);
    this.closeModalCB = this.closeModalCB.bind(this);
    this.changeSelectedMediaCB = this.changeSelectedMediaCB.bind(this);
    this.getSelectedMediaIndex = this.getSelectedMediaIndex.bind(this);
  }

  closeModalCB() {
    this.setState({
      isModalOpen: false,
    });
  }

  changeSelectedMediaCB(newMedia) {
    this.setState({
      selectedMedia: newMedia,
    });
  }

  toRight() {
    var leftMediaArray = this.state.leftMediaArray;
    var rightMediaArray = this.state.rightMediaArray;
    var displayedMediaArray = this.state.displayedMediaArray;

    if (rightMediaArray.length !== 0) {
      displayedMediaArray.push(rightMediaArray.shift());
      leftMediaArray.push(displayedMediaArray.shift());
    }
    this.setState({
      rightMediaArray: rightMediaArray,
      leftMediaArray: leftMediaArray,
      displayedMediaArray: displayedMediaArray,
    });
  }

  toLeft() {
    var leftMediaArray = this.state.leftMediaArray;
    var rightMediaArray = this.state.rightMediaArray;
    var displayedMediaArray = this.state.displayedMediaArray;

    if (leftMediaArray.length !== 0) {
      displayedMediaArray.unshift(leftMediaArray.pop());
      rightMediaArray.unshift(displayedMediaArray.pop());
    }
    this.setState({
      rightMediaArray: rightMediaArray,
      leftMediaArray: leftMediaArray,
      displayedMediaArray: displayedMediaArray,
    });
  }

  prepareStateForMedia(wasChanged = false, prevState = null) {
    var leftMediaArray = [];
    var rightMediaArray = [];
    var displayedMediaArray = [];
    var displayedPreviewCount = this.props.displayedPreviewCount;
    var medias = this.props.media.flatMap((src) =>
      src.mime_type.startsWith(this.props.mimeType) ? src : []
    );

    if (this.state.allMediaArray.length !== medias.length) {
      if (medias.length !== 0) {
        if (medias.length <= displayedPreviewCount) {
          displayedMediaArray = medias;
        } else {
          displayedMediaArray = medias.slice(-displayedPreviewCount);
          leftMediaArray = medias.slice(0, -displayedPreviewCount);
        }
      }
    } else {
      if (medias.length !== 0) {
        if (medias.length <= displayedPreviewCount) {
          displayedMediaArray = medias;
        } else {
          leftMediaArray = medias.slice(0, this.state.leftMediaArray.length);
          displayedMediaArray = medias.slice(
            this.state.leftMediaArray.length,
            this.state.leftMediaArray.length + displayedPreviewCount
          );
          rightMediaArray = medias.slice(
            this.state.leftMediaArray.length + displayedPreviewCount
          );
        }
      }
    }
    var selectedMedia = null;

    if (prevState) {
      if (wasChanged) {
        var lastSelectedMedia = prevState.selectedMedia;
        var lastIndex = prevState.allMediaArray.indexOf(lastSelectedMedia);

        if (medias.length === 0) {
          selectedMedia = null;
        } else {
          if (lastIndex === 0) {
            selectedMedia = medias[0];
          } else if (lastIndex === prevState.allMediaArray.length - 1) {
            selectedMedia = medias[medias.length - 1];
          } else {
            selectedMedia = medias[lastIndex];
          }
        }
      } else {
        selectedMedia =
          medias[prevState.allMediaArray.indexOf(prevState.selectedMedia)];
      }
    }

    this.setState({
      allMediaArray: medias,
      leftMediaArray: leftMediaArray,
      displayedMediaArray: displayedMediaArray,
      rightMediaArray: rightMediaArray,
      displayedPreviewCount: displayedPreviewCount,
      selectedMedia: selectedMedia,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      return true;
    }

    if (nextProps !== this.props) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.props.media) !== JSON.stringify(prevProps.media)) {
      var medias = this.props.media.flatMap((src) =>
        src.mime_type.startsWith(this.props.mimeType) ? src : []
      );

      var prevMedias = prevProps.media.flatMap((src) =>
        src.mime_type.startsWith(this.props.mimeType) ? src : []
      );

      if (medias.length !== prevMedias.length) {
        this.prepareStateForMedia(true, prevState);

        if (medias.length === 0) {
          this.closeModalCB();
        }
      } else {
        this.prepareStateForMedia(false, prevState);
      }
    }
  }

  componentDidMount() {
    this.prepareStateForMedia();
  }

  getSelectedMediaIndex() {
    var currentIndex;
    if (this.state.allMediaArray.length > 0) {
      if (this.state.allMediaArray.indexOf(this.state.selectedMedia) === -1) {
        currentIndex = 0;
      } else {
        currentIndex = this.state.allMediaArray.indexOf(
          this.state.selectedMedia
        );
      }
    } else currentIndex = -1;

    return currentIndex;
  }

  render() {
    const leftButton = (
      <button className="rounded-circle media_l_btn" onClick={this.toLeft}>
        {arrowLeftIcon}
      </button>
    );

    const rightButton = (
      <button className="rounded-circle media_r_btn" onClick={this.toRight}>
        {arrowRightIcon}
      </button>
    );

    if (this.state.displayedMediaArray.length === 0)
      return <> Медиа-файлы отсутствуют</>;

    return (
      <div className="d-flex flex-wrap justify-content-start justify-content-lg-center">
        <MediaModal
          mediaArray={this.state.allMediaArray}
          currentMediaIndex={this.getSelectedMediaIndex()}
          isOpen={this.props.isModalAlwaysOpen ? true : this.state.isModalOpen}
          removeFunc={this.props.removeFunc}
          parentRowId={this.props.parentRowId}
          closeModalCB={this.closeModalCB}
          changeSelectedMediaCB={this.changeSelectedMediaCB}
          isShowRemoveButton={this.props.isShowRemoveButton}
          isHideCloseButton={this.props.isModalAlwaysOpen}
          updateCommentFunc={this.props.updateCommentFunc}
          isCommentEditable={this.props.isCommentEditable}
        />

        {this.state.displayedMediaArray.map((media, idx) => (
          <div
            className="flex-column"
            key={media.file + idx}
            abs={media.file + idx}
          >
            <div className="card media_thumbnail_card">
              {this.state.leftMediaArray.length !== 0 &&
                idx === 0 &&
                leftButton}
              {this.state.rightMediaArray.length !== 0 &&
                idx === this.state.displayedMediaArray.length - 1 &&
                rightButton}
              <div className="card-body p-1 ">
                <img
                  onClick={() => {
                    this.setState({
                      selectedMedia: media,
                      isModalOpen: true,
                    });
                  }}
                  src={media.thumbnail ? media.thumbnail : placeholderImage}
                  className="media_thmb"
                  alt={media.file}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default MediaGalery;
