import React, { Component } from "react";
import "../../components/styles.css";
import dataService from "../../services/data.service";
import MediaGalery from "../media/MediaGalery";

class OrderGaleryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      media: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    dataService
      .getOrderMedia(this.props.match.params.id)
      .then((response) => {
        this.setState({ media: response.data, isLoaded: true });
      })
      .catch((err) => {
        this.setState({ error: err });
      });
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <div className="col-12 col-lg-10 col-xl-11 mt-3">
          <div className=" d-flex flex-wrap justify-content-center justify-content-lg-center text-center">
            <MediaGalery
              displayedPreviewCount={this.state.media.length}
              mimeType=""
              media={this.state.media}
              isShowRemoveButton={false}
              isCommentEditable={false}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-12 col-lg-10 col-xl-11 mt-3">
          <div className="d-flex flex-wrap justify-content-start justify-content-lg-center">
            Галерея загружается
          </div>
        </div>
      );
    }
  }
}

export default OrderGaleryPage;
