import React, { Component } from "react";
import AuthService from "../../services/auth.service";
import {
  exitIcon,
  fileRuledIcon,
  gearIcon,
  listIcon,
  peoplesIcon, photoIcon,
} from "./icons.consts";

export default class TopSide extends Component {

  componentDidMount() {}

  render() {
    if (this.props.user === null) {
      return <> </>;
    } else {
      const user = this.props.user;
      return (
        <div className="row d-lg-none page_structure border-bottom ">
          <div
            className="text-white"
            style={{ width: "40vw", marginTop: "1vh", marginBottom: "1vh" }}
          >
            {user.is_superuser ? (
              "Администратор"
            ) : (
              <div>
                <p> {user.name} </p> <p>{user.city}</p>
              </div>
            )}
          </div>

          <div
            className=""
            style={{
              width: "250px",
              marginLeft: "auto",
              padding: 0,
              marginRight: "10px",
              marginTop: "1vh",
              marginBottom: "1vh",
            }}
          >
            <div
              className="text-center"
              style={{ display: "", width: "50px", float: "right" }}
            >
              <a
                className="btn top_menu_btn "
                onClick={() => {
                  AuthService.logout();
                }}
                href={"/login"}
              >
                {exitIcon}
              </a>
            </div>

            <div
              className="text-center"
              style={{ display: "", width: "50px", float: "right" }}
            >
              <a
                className="btn top_menu_btn "
                href={"/gallery"}
              >
                {photoIcon}
              </a>
            </div>

            {user.is_superuser && (
              <div>
                <div
                  className="text-center"
                  style={{ display: "", width: "50px", float: "right" }}
                >
                  <a className="btn top_menu_btn " href="/settings">
                    {gearIcon}
                  </a>
                </div>

                <div
                  className="text-center"
                  style={{ display: "", float: "right", width: "50px" }}
                >
                  <a className="btn top_menu_btn " href="/">
                    {listIcon}
                  </a>
                </div>

                <div
                  className="text-center"
                  style={{width: "50px", float: "right" }}
                >
                  <a className="btn top_menu_btn " href="/measurements">
                    {fileRuledIcon}
                  </a>
                </div>
                <div
                  className="text-center"
                  style={{ display: "", width: "50px", float: "right" }}
                >
                  <a className="btn top_menu_btn " href="/employees">
                    {peoplesIcon}
                  </a>
                </div>
              </div>
            )}

            {user.type === "i" && <div>
              <div
                className="text-center"
                style={{ display: "", width: "50px", float: "right" }}
              >
                <a className="btn top_menu_btn " href="/remains">
                  {listIcon}
                </a>
              </div>
              <div
                className="text-center"
                style={{width: "50px", float: "right" }}
              >
                <a className="btn top_menu_btn " href="/">
                  {fileRuledIcon}
                </a>
              </div>
            </div>}
          </div>
        </div>
      );
    }
  }
}
