import axios from "axios";

//export const BACKEND_URL = "http://92.255.76.72:8000";
//export const BACKEND_URL = "http://localhost:8000";
// export const BACKEND_URL = "http://192.168.4.141:8000";
export const BACKEND_URL = "https://www.bobr-master.ru:8443";
export const GALLERY_URL = "https://bobr-master.ru/s3gallery";

export default axios.create({
  baseURL: BACKEND_URL+"/api/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods" : "GET,POST,PATCH, PUT, DELETE,OPTIONS",
   "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization, Access-Control-Allow-Origin"
  }
});
