import http from "../http-common";

export default function authHeader() {
  return new Promise(function (resolve, reject) {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access) {
      if (
        Date.now() >=
        JSON.parse(atob(user.access.split(".")[1])).exp * 1000
      ) {
        http
          .post("/refresh/", { refresh: user.refresh })
          .then((response) => {
            user.access = response.data.access;
            user.refresh = response.data.refresh;
            localStorage.setItem("user", JSON.stringify(user));
            resolve({ Authorization: `Bearer ${response.data.access}` });
          })
          .catch(function (error) {
            reject(error);
          });
      } else {
        resolve({ Authorization: `Bearer ${user.access}` });
      }
    } else {
      reject("Вы не авторизованы");
    }
  });
}
