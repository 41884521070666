import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchData,
  removeMeasurementMedia,
  updateMeasurementMediaComment
} from "../../actions/data.actions";
import "../../components/styles.css";
import dataService from "../../services/data.service";
import { getPayUrlByCity } from "../../utils";
import QRModal from "../common/QRModal";
import MediaDownloader from "../media/MediaDownloader";
import MediaGalery from "../media/MediaGalery";

class MeasurementItem extends Component {

  checkIsMediaProcessed = () => {
    for (var i = 0; i < this.props.item.measurement_media.length; i++) {
      if (!this.props.item.measurement_media[i].processed) {
        return false;
      }
    }

    return true;
  };
  async refreshWhileProccessing() {
    await new Promise((r) => setTimeout(r, 7000));
    this.props.getData(this.props.item.id);

    if (!this.checkIsMediaProcessed()) this.refreshWhileProccessing();
  }

  componentDidMount() {
    if (!this.checkIsMediaProcessed()) this.refreshWhileProccessing();
  }

  componentDidUpdate() {
    if (!this.checkIsMediaProcessed()) this.refreshWhileProccessing();
  }

  render() {
    return (
      <div className="row mt-1 text-start text-lg-center">
        <div className="col-12   ">
          <div className="card" style={{ border: "solid 2px black" }}>
            <div className="col-12 card-body">
              <div className="row">
                <div className="col-12  d-block col-lg-7">
                  <div className="row ">
                    <div className="col-12 col-lg-2 d-block d-lg-inline mt-3 mt-lg-0">
                      <div className="row">
                        <div className="d-lg-none col-3 small_text fw-bold">
                          Дата:
                        </div>
                        <div className="col-9 col-lg-12">
                          {this.props.item.date}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2 d-block d-lg-inline mt-3 mt-lg-0">
                      <div className="row">
                        <div className="d-lg-none col-3 small_text fw-bold">
                          ФИО:
                        </div>
                        <div className="col-9 col-lg-12">
                          {this.props.item.appointed_measurers.map(
                            (empl, idx) => (
                              <p key={empl.idx}>{empl.name}</p>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2 d-block d-lg-inline mt-3 mt-lg-0">
                      <div className="row">
                        <div className="d-lg-none col-3 small_text fw-bold">
                          Замер:
                        </div>
                        <div className="col-9 col-lg-12">
                          {this.props.item.measurement_id}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2 d-block d-lg-inline mt-3 mt-lg-0">
                      <div className="row">
                        <div className="d-lg-none col-3 small_text fw-bold">
                          Адрес:
                        </div>
                        <div className="col-9 col-lg-12">
                          {this.props.item.address}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-2 d-block d-lg-inline mt-3 mt-lg-0">
                      <div className="row">
                        <div className="d-lg-none col-3 small_text fw-bold">
                          Город:
                        </div>
                        <div className="col-9 col-lg-12">
                          {this.props.item.city}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-2 d-block d-lg-inline mt-3 mt-lg-0">
                      <div className="row">
                        <div className="d-lg-none col-4 small_text fw-bold">
                          QR оплаты:
                        </div>
                        <div className="col-8 col-lg-12">
                          <QRModal
                            value={getPayUrlByCity(this.props.item.city)}
                            styles="btn btn-success small_text order_buttons"
                            title=" QR для оплаты"
                            buttonText="QR оплаты"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-lg-5 ">
                  <div className="row ">
                    <div className="col-12 col-lg-4 d-block d-lg-inline mt-3 mt-lg-0">
                      <div className="row">
                        <div className="d-lg-none col-3 small_text fw-bold mb-2">
                          Фото:
                        </div>
                        <div className="col-8 col-lg-12 ">
                          <MediaGalery
                            displayedPreviewCount={2}
                            mimeType="image"
                            parentRowId={this.props.item.id}
                            removeFunc={this.props.removeMeasurementMedia}
                            media={this.props.item.measurement_media}
                            isShowRemoveButton={true}
                            updateCommentFunc={this.props.updateMediaComment}
                            isCommentEditable={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 d-block d-lg-inline mt-3 mt-lg-0">
                      <div className="row">
                        <div className="d-lg-none col-3 small_text fw-bold mb-2">
                          Видео:
                        </div>
                        <div className="col-8 col-lg-12 ">
                          <MediaGalery
                            displayedPreviewCount={2}
                            mimeType="video"
                            parentRowId={this.props.item.id}
                            removeFunc={this.props.removeMeasurementMedia}
                            media={this.props.item.measurement_media}
                            isShowRemoveButton={true}
                            updateCommentFunc={this.props.updateMediaComment}
                            isCommentEditable={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 d-block d-lg-inline mt-3 mt-lg-0">
                      <div className="row">
                        <div className="d-lg-none col-12 small_text fw-bold mb-2">
                          Загрузить:
                        </div>
                        <div className="col-12 col-lg-12 text-center">
                          <MediaDownloader
                            indexInStateTree={this.props.idx}
                            payload={{
                              measurementId: parseInt(this.props.item.id),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    item: state.data.results[ownProps.idx],
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getData: (id) => {
      dispatch(fetchData(dataService.getMeasurement, id, ownProps.idx));
    },

    removeMeasurementMedia: (
      mediaId,
      measurementId,
      indexOfMediaInStateTree
    ) => {
      dispatch(
        removeMeasurementMedia(
          mediaId,
          measurementId,
          ownProps.idx,
          indexOfMediaInStateTree
        )
      );
    },

    updateMediaComment: (mediaId, measurementId, data) => {
      dispatch(updateMeasurementMediaComment(mediaId, measurementId, data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MeasurementItem);
