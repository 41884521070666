import React, { Component } from "react";
import logo from "../../assets/logo.png";
import AuthService from "../../services/auth.service";

export default class LeftSide extends Component {

  componentDidMount() {}

  render() {
    if (this.props.user === null) {
      return <> </>;
    } else {
      const user = this.props.user;
      return (
        <div className=" d-none d-lg-block col-lg-2 col-xl-1  page_structure text-center">
          <div className=" align-items-center align-items-sm-start text-white vh-100 ms-2">
            <img width="80" src={logo} alt="logo" />
            {!this.props.user.is_superuser && (
              <div>
                <div className=" mt-3">
                  <span className="d-none d-sm-inline text-break">
                    {user.name}
                  </span>
                </div>

                <div className="row">
                  <div className="mt-3 alert alert-dark p-1 col-12 col-lg-8 offset-lg-2 text-break">
                    {user.city}
                  </div>
                </div>
              </div>
            )}

            {this.props.user.is_superuser && (
              <div>
                <div className="text-center ">
                  <div className="mt-3">
                    <span className="text-break mt-3 "> Администратор </span>
                  </div>

                  <div className="row mt-3  ">
                    <div className="col-12 col-lg-12">
                      <a
                        className="btn btn-outline-light mt-2 w-100 text-break "
                        href="/"
                      >
                        Монтажи
                      </a>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12 col-lg-12 ">
                      <a
                        className="btn btn-outline-light mt-2 w-100 text-break"
                        href="/measurements"
                      >
                        Замеры
                      </a>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12 col-lg-12 ">
                      <a
                        className="btn btn-outline-light mt-2 w-100 text-break"
                        href="/employees"
                      >
                        Сотрудники
                      </a>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12 col-lg-12 ">
                      <a
                        className="btn btn-outline-light mt-2 w-100 text-break"
                        href="/settings"
                      >
                        Управление
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.props.user.type === 'i' && (
              <div>
                <div className="text-center ">
                  <div className="row mt-3  ">
                    <div className="col-12 col-lg-12">
                      <a
                        className="btn btn-outline-light mt-2 w-100 text-break "
                        href="/"
                      >
                        Монтажи
                      </a>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12 col-lg-12 ">
                      <a
                        className="btn btn-outline-light mt-2 w-100 text-break"
                        href="/remains"
                      >
                        Остатки
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row  ">
              <div className="col-12 col-lg-12">
                <a
                  className="btn btn-outline-light mt-2 w-100 text-break "
                  href="/gallery"
                >
                  Галерея
                </a>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-12 col-lg-12">
                <a
                  className="btn btn-outline-light mt-2 w-100 text-break"
                  onClick={() => {
                    AuthService.logout();
                  }}
                  href={"/login"}
                >
                  Выход
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
