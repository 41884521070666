import React, { Component } from "react";

const defaultState = {
  isWritingState: false,
  commentInput: "",
};

class MediaComment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaultState,
    };

    this.openInput = this.openInput.bind(this);
    this.closeInput = this.closeInput.bind(this);

    this.onChangeCommentInput = this.onChangeCommentInput.bind(this);
    this.saveComment = this.saveComment.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      return true;
    }

    if (nextProps !== this.props) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.mediaId !== this.props.mediaId) {
      this.closeInput();
      this.setState({
        commentInput: this.props.comment,
      });
    }
  }
  componentDidMount() {
    this.setState({
      commentInput: this.props.comment,
    });
  }

  closeInput() {
    this.setState({
      isWritingState: false,
    });
  }
  openInput() {
    this.setState({
      isWritingState: true,
    });
  }

  onChangeCommentInput(e) {
    this.setState({
      commentInput: e.target.value,
    });
  }

  saveComment() {
    var comment = this.state.commentInput;
    var data = {
      comment: comment,
    };

    this.props.updateCommentFunc(
      this.props.mediaId,
      this.props.parentRowId,
      data
    );
    this.setState({
      isWritingState: false,
    });
  }

  renderWritingState = () => {
    return (
      <div className="row d-flex justify-content-center">
        <div className="text-center w-75 w-lg-50">
          <textarea
            className="form-control"
            onChange={this.onChangeCommentInput}
            defaultValue={this.state.commentInput}
          />
        </div>

        <div className="text-center mt-2">
          <button
            className="btn btn-sm btn-outline-dark ms-2"
            onClick={this.closeInput}
          >
            Отмена
          </button>
          <button
            className="btn btn-sm btn-outline-dark ms-2"
            onClick={this.saveComment}
          >
            Сохранить изменения
          </button>
        </div>
      </div>
    );
  };

  renderReadingState = () => {
    return (
      <div className="row d-flex justify-content-center">
        <div className="text-center w-50">
          <p>
            {this.props.comment ? "Комментарий: " : <br />} {this.props.comment}
          </p>

          {this.props.isCommentEditable ? (
            <button
              className="btn btn-sm btn-outline-dark"
              onClick={this.openInput}
            >
              {this.props.comment
                ? "Редактировать комментарий"
                : "Написать комментарий"}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  render() {
    if (this.state.isWritingState) return this.renderWritingState();
    else return this.renderReadingState();
  }
}

export default MediaComment;
