import React, { Component } from "react";
import dataService from "../../services/data.service";
import DataPage from "../common/DataPage";
import OrderItem from "./OrderItem";

class Orders extends Component {
  render() {
    return (
      <DataPage
        pagination={true}
        showSearch={true}
        fetchDataFunc={dataService.getOrders}
        itemComponent={OrderItem}
      />
    );
  }
}

export default Orders;
