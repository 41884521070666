import data from "./data.reducer";
import {pageState} from "./page.reducer";
import {combineReducers} from "redux";

const reducer = combineReducers({
  data,
  pageState,
});

export default reducer; 
