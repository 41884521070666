
export const LoadingContent = () => (
  <div className="content_loading row align-items-center g-2">
    <div className="load_spinner_container col-5 text-end">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Загрузка...</span>
      </div>
    </div>
    <div className="col-7 text-start"> Загрузка... </div>
  </div>
);
