import {
  DATA_LIST_RESPONSE,
  DATA_ITEM_RESPONSE,
  REMOVE_ORDER_MEDIA_FROM_STATE,
  REMOVE_MEASUREMENT_MEDIA_FROM_ORDER_PAGE_STATE,
  REMOVE_MEASUREMENT_MEDIA_FROM_STATE,
  UPDATE_ORDER_MEDIA_COMMENT_IN_STATE,
  UPDATE_MEASUREMENT_MEDIA_COMMENT_IN_ORDER_STATE,
  UPDATE_MEASUREMENT_MEDIA_COMMENT_IN_STATE,
} from "../actions/data.actions";

export default function data(state = {}, action) {
  switch (action.type) {
    case DATA_LIST_RESPONSE:
      return action.data;

    case DATA_ITEM_RESPONSE: {
      let _state = { ...state };
      if (action.single) {
        let data = action.data;
        _state = { ..._state, ...data };
      } else {
        _state.results[action.stateTreeIndex] = action.data;
      }
      return _state;
    }

    case REMOVE_ORDER_MEDIA_FROM_STATE: {
      let results = [...state.results];
      let order_media = results[action.stateTreeIndex].order_media.filter(
        function (f) {
          return f.id !== action.mediaId;
        }
      );
      results[action.stateTreeIndex] = {
        ...results[action.stateTreeIndex],
        order_media,
      };
      return { ...state, results };
    }

    case REMOVE_MEASUREMENT_MEDIA_FROM_ORDER_PAGE_STATE: {
      let results = [...state.results];
      let order = { ...results[action.stateTreeIndex] };
      let measurement = { ...results[action.stateTreeIndex].measurement };
      let measurement_media = measurement.measurement_media.filter(function (
        f
      ) {
        return f.id !== action.mediaId;
      });
      measurement = { ...measurement, measurement_media };
      order = { ...order, measurement };
      results[action.stateTreeIndex] = order;
      return { ...state, results };
    }

    case REMOVE_MEASUREMENT_MEDIA_FROM_STATE: {
      let results = [...state.results];
      let measurement_media = results[
        action.stateTreeIndex
      ].measurement_media.filter(function (f) {
        return f.id !== action.mediaId;
      });
      results[action.stateTreeIndex] = {
        ...results[action.stateTreeIndex],
        measurement_media,
      };
      return { ...state, results };
    }

    case UPDATE_ORDER_MEDIA_COMMENT_IN_STATE: {
      let results = [...state.results];
      let order = results.filter(function (f) {
        return f.id === action.parentId;
      });
      let idx_in_tree = results.indexOf(order[0]);
      let order_media = results[idx_in_tree].order_media;
      let updated_media = order_media.filter(function (f) {
        return f.id === action.mediaId;
      })[0];
      let updated_media_idx = order_media.indexOf(updated_media);
      updated_media.comment = action.comment;
      order_media[updated_media_idx] = updated_media;
      results[idx_in_tree] = { ...results[idx_in_tree], order_media };
      return { ...state, results };
    }

    case UPDATE_MEASUREMENT_MEDIA_COMMENT_IN_ORDER_STATE: {
      let results = [...state.results];
      let order = results.filter(function (f) {
        return f.id === action.parentId;
      });
      let idx_in_tree = results.indexOf(order[0]);
      let measurement = results[idx_in_tree].measurement;
      let measurement_media = measurement.measurement_media;
      let updated_media = measurement_media.filter(function (f) {
        return f.id === action.mediaId;
      })[0];
      let updated_media_idx = measurement_media.indexOf(updated_media);
      updated_media.comment = action.comment;
      measurement_media[updated_media_idx] = updated_media;
      measurement = { ...measurement, measurement_media };
      results[idx_in_tree] = { ...results[idx_in_tree], measurement };
      return { ...state, results };
    }

    case UPDATE_MEASUREMENT_MEDIA_COMMENT_IN_STATE: {
      let results = [...state.results];
      let measurement = results.filter(function (f) {
        return f.id === action.parentId;
      });
      let idx_in_tree = results.indexOf(measurement[0]);
      let measurement_media = results[idx_in_tree].measurement_media;
      let updated_media = measurement_media.filter(function (f) {
        return f.id === action.mediaId;
      })[0];
      let updated_media_idx = measurement_media.indexOf(updated_media);
      updated_media.comment = action.comment;
      measurement_media[updated_media_idx] = updated_media;
      results[idx_in_tree] = { ...results[idx_in_tree], measurement_media };
      return { ...state, results };
    }

    default:
      return state;
  }
}
